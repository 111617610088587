import '../paedml-modal.tag'
import '../paedml-form-input.tag'
import '../paedml-qrcode.tag'
import '../paedml-icon.tag'

<paedml-modal-info-bookmark>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <div class="row">
        <div class="col-sm-6">
            <paedml-qrcode content="{parent.qrCode}" />
            <div class="qr-text-bottom">
              <a href="{parent.qrCode()}" target="blank" style="color: black;"> 
              <paedml-icon icon="far-share-square" inv={true}></paedml-icon> {parent.data.name}</a></div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <paedml-form-input name="description" col="5" label="Beschreibung" readonly />
        </div>
      </div>

      <div class="row" show={parent.variant === 'bookmarks'}>
        <div class="col-sm-12">
            <paedml-form-input name="schoolTypesDP" col="9" label="Schulart(en)" readonly />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
            <paedml-form-input name="tagsDP" col="7" label="Tag(s)" readonly />
        </div>
      </div>
    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../../util/modal-util'
      import store from '../../store'
      import { client } from '../../util/api-util'
      import * as mapper from '../../mapper'
      import * as R from 'ramda'
      import Session from '../../store/session'
      import Masterdata from '../../config/masterdata'
      import API from '../../api'

      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      this.variant = opts.variant

      this.on('rowMappingRequested', cols => {
        this.data.name = cols[2]
        this.data.url = cols[4]
console.log(cols)
        autoMapRowToData(this, cols)
      })

      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }

      this.qrCode = () => this.data.url

    </script>
</paedml-modal-info-bookmark>
