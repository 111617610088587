import MasterData from '../config/masterdata'
import {createLut, reversePairs} from '../util/mapping-util'
import * as collect from '../util/collectors'
import * as R from 'ramda'
import Session from '../store/session'
import naturalSort from 'es6-natural-sort'

const rolesById = createLut(MasterData.roles)
const roleTypesById = createLut(MasterData.roleTypes)

export const mapUserDetail = ({id, userName, givenName, surname, displayName, schoolTypes, profile, mainSchoolType, ...rest}) => {
  const roles = mapRoles(rest.roles)
  const roleType = roleTypesById[rest.roleType]
  const Raum = rest.roomFilter

  return {  
    id,
    userName,
    givenName,
    surname,
    displayName,
    roles,
    schoolTypes: R.pick(schoolTypes || [])(rest.schularten),
    mainSchoolType,
    roleAbbrev: roleType.shortName,
    profile: profile.avatarUrl ? profile : {...profile, avatarUrl: `img/avatar-${roleType.id}.jpg`},
    filters: {
      Schulart: R.compose(R.values, R.pick(schoolTypes || []))(rest.schularten || {}),
      SchulartOverall: R.compose(R.prepend('Übergreifend'), R.values, R.pick(schoolTypes || []))(rest.schularten || {}),
      Rollen: R.compose(R.map(R.prop('shortName')), R.filter(R.propEq('roleTypeId', 4)))(MasterData.roles),
      Klasse: R.map(R.nth(1))(Session.lut.klassenBySchulart[undefined]),
      Vermerk: ['Duplikat', 'Datensatz fehlerhaft', 'Temporärer Fehler', 'Importfehler', 'Benutzername', 'Externe ID Duplikat'],
      VermerkBSA: ['Duplikat'],
      Status: ['in Verarbeitung', 'Verarbeitungsfehler'],
      Aktiv: ['ja', 'nein'],
      Favoriten: ['Übrige Karten', 'Alle Karten'],
      'Priorität': ["1", "2", "3", "4", "5", "6", "7", "8"],
      Raum,
      QuotaStatus: [ "Limit überschritten", "Warngrenze überschritten", "OK", "Unbegrenzt", "UNBEKANNT",],
      JaNein: ["ja", "nein"],
      Plattform: R.values(Session.map.platforms),
      Tag: Session.map.tags
    }
  }
}

const mapRoles = roles => roles
    .filter(r => rolesById[r])
    .map(r => rolesById[r])

const mapSchularten = collect.asMap(R.prop('id'), R.prop('name'))
const mapSchulartenLong = collect.asMap(R.prop('id'), v => `${v.description} (${v.name})`)
const mapUniqueKlassen = R.compose(
    R.map(R.values),
    R.uniqBy(R.compose(R.toLower, R.prop('name'))),
    R.flatten,
    R.map(R.prop('schoolClasses'))
)
const mapKlassenLut = collect.asMap(R.prop('id'), R.pipe(R.prop('schoolClasses'),R.map(R.values)))

const mapKlassen = schulartenMitKlassen => R.assoc(undefined, mapUniqueKlassen(schulartenMitKlassen), mapKlassenLut(schulartenMitKlassen))

export const mapSchulartenWithKlassen = (schulartenWithKlassen) => {
  Session.map.schularten = mapSchularten(schulartenWithKlassen)
  Session.map.schulartenLong = mapSchulartenLong(schulartenWithKlassen)
  Session.map.schulartenLongOverall = {0: 'Übergreifend', ...Session.map.schulartenLong}
  
  Session.lut.schularten = reversePairs(Session.map.schularten)
  Session.lut.klassenBySchulart = mapKlassen(schulartenWithKlassen)
  
  Session.lut.klassenBySchulart = R.mapObjIndexed((klassen, schulartId) => {
    const lut = {}
    const val = klassen.map(v => {
      const index = v[1] + '$$' + v[0]
      lut[index] = v
      return index
    })
    val.sort(naturalSort)
    return val.map(index => lut[index])
  })(Session.lut.klassenBySchulart)
}

export const mapProjectsWithMembers = (projects) => {
  Session.map.projectsWithMembers = R.reduce((map, prj ) => {
    map[prj.name]=prj.members.map(m=>{return m.userName})
    return map
  }, {},projects)
}