const Masterdata = {
    roles: [
        { "id": 1, "name": "Systemadministratoren", "displayName": "System-Administratoren", "description": "System-Administratoren", "roleTypeId": 2, "shortName": "System-Adm" },
        { "id": 2, "name": "Dienstkonto", "displayName": "Dienstkonto", "description": "Dienstkonto", "roleTypeId": 5, "shortName": "Dienstkonto" },
        { "id": 3, "name": "Schueler", "displayName": "Schüler", "description": "Schüler", "roleTypeId": 7, "shortName": "Schüler" },
        { "id": 4, "name": "Lehrer", "displayName": "Lehrer", "description": "Lehrer", "roleTypeId": 6, "shortName": "Lehrer" },
        { "id": 5, "name": "ADMSchulart", "displayName": "Schulart-Administratoren", "description": "Schulart-Administratoren", "roleTypeId": 3, "shortName": "Schulart-Adm" },
        { "id": 6, "name": "Schueleradministratoren", "displayName": "Schüler-Administratoren", "description": "Schüler-Administratoren", "roleTypeId": 4, "shortName": "Schüler-Adm" },
        { "id": 7, "name": "Lehreradministratoren", "displayName": "Lehrer-Administratoren", "description": "Lehrer-Administratoren", "roleTypeId": 4, "shortName": "Lehrer-Adm" },
        { "id": 8, "name": "Klassenadministratoren", "displayName": "Klassen-Administratoren", "description": "Klassen-Administratoren", "roleTypeId": 4, "shortName": "Klassen-Adm" },
        { "id": 9, "name": "Raumadministratoren", "displayName": "Raum-Administratoren", "description": "Raum-Administratoren", "roleTypeId": 4, "shortName": "Raum-Adm" },
        { "id": 10, "name": "Computeradministratoren", "displayName": "Computer-Administratoren", "description": "Computer-Administratoren", "roleTypeId": 4, "shortName": "Computer-Adm" },
        { "id": 11, "name": "Druckeradministratoren", "displayName": "Drucker-Administratoren", "description": "Drucker-Administratoren", "roleTypeId": 4, "shortName": "Drucker-Adm" },
        { "id": 12, "name": "JobQueue-Administratoren", "displayName": "JobQueue-Administratoren", "description": "JobQueue-Administratoren", "roleTypeId": 4, "shortName": "JobQueue-Adm" },
        { "id": 13, "name": "Firewalladministratoren", "displayName": "Firewall-Administratoren", "description": "Firewall-Administratoren", "roleTypeId": 4, "shortName": "Firewall-Adm" },
        { "id": 14, "name": "Versetzenadministratoren", "displayName": "Versetzen-Administratoren", "description": "Versetzen-Administratoren", "roleTypeId": 4, "shortName": "Versetzen-Adm" },
        { "id": 15, "name": "Importadministratoren", "displayName": "Import-Administratoren", "description": "Import-Administratoren", "roleTypeId": 4, "shortName": "Import-Adm" },
        { "id": 16, "name": "Globalfirewalladministratoren", "displayName": "Globale Firewall-Admins", "description": "Globale Firewall-Admins", "roleTypeId": 1, "shortName": "Globaler Firewall-Adm" },
        { "id": 19, "name": "Profilverteiler", "displayName": "Profilverteiler", "description": "Profilverteiler", "roleTypeId": 4, "shortName": "Profilverteiler" },
		{ "id": 20, "name": "O365Administratoren", "displayName": "O365-Administratoren", "description": "O365-Administratoren", "roleTypeId": 4, "shortName": "O365-Adm" },
        { "id": 21, "name": "Moodle-Administratoren", "displayName": "Moodle-Administratoren", "description": "Moodle-Administratoren", "roleTypeId": 4, "shortName": "Moodle-Adm" },
        { "id": 22, "name": "Profiladministratoren", "displayName": "Profil-Administratoren", "description": "Profiladministratoren", "roleTypeId": 4, "shortName": "Profil-Adm" },
        { "id": 23, "name": "ExterneID-Administratoren", "displayName": "ExterneID-Administratoren", "description": "ExterneID-Administratoren", "roleTypeId": 4, "shortName": "ExterneID-Adm" },
        { "id": 24, "name": "Softwareverteiler", "displayName": "Softwareverteiler", "description": "Softwareverteiler", "roleTypeId": 4, "shortName": "Softwareverteiler" },
        { "id": 25, "name": "Softwareadministratoren", "displayName": "Software-Administratoren", "description": "Softwareadministratoren", "roleTypeId": 4, "shortName": "Software-Adm" },
        { "id": 26, "name": "LAPS-Administratoren", "displayName": "LAPS-Administratoren", "description": "LAPS-Administratoren", "roleTypeId": 4, "shortName": "LAPS-Adm" },
        { "id": 27, "name": "Link-Administratoren", "displayName": "Link-Administratoren", "description": "Link-Administratoren", "roleTypeId": 4, "shortName": "Link-Adm" },
    ],

    roleTypes: [
        {"id": 1, "suffix": "fadm", "shortName": "FADM"},
        {"id": 2, "suffix": "sysadm", "shortName": "SYSA"},
        {"id": 3, "suffix": "sadm", "shortName": "SADM"},
        {"id": 4, "suffix": "radm", "shortName": "RADM"},
        {"id": 5, "suffix": null, "shortName": "DK"},
        {"id": 6, "suffix": null, "shortName": "L"},
        {"id": 7, "suffix": null, "shortName": "S"},
    ],

    shareFolderAccessPolicyMap: {
        0: "Schüler*innen: lesend, schreibend",
        1: "Schüler*innen: lesend",
        2: "Schüler*innen: verweigert"
    },

    projectShareFolderAccessPolicyMap: {
        0: "Mitglieder: lesend, schreibend",
        1: "Mitglieder: lesend",
        2: "Mitglieder: verweigert"
    },

    jobType: {
        0: "erstellen",
        1: "ändern",
        2: "löschen",
        3: "verschieben",
        4: "n.v.",
		5: "Rechtekorrektur",
		6: "leeren"
    },

    jobTarget: {
        0: "Schüler",
        1: "Lehrer",
        2: "Klassenarbeit",
        3: "System",
        4: "Klasse",
        5: "Schulart",
        6: "Projekt",
		7: "Ausgeteilte Dateien",
		8: "Ordner",
        9: "Computer"
    },

    taskStatus: {
        0: "geplant",
        1: "wird ausgeführt",
        2: "beendet",
        3: "fehlerhaft",
        4: "verzögert",
        5: "abgebrochen"
    },

    firewallCategories: {
        4: "Anti-Spyware Updates", 
        17: "Arbeitssuche", 
        5: "Audio &amp; Video", 
        6: "Bankwesen", 
        9: "Chat &amp; Foren", 
        12: "Dateihosting", 
        7: "Drogen", 
        2: "Erwachsenen Inhalt", 
        25: "Freizeit &amp; Urlaub", 
        3: "Gewalt", 
        13: "Glücksspiel", 
        15: "Hacking &amp; Warez", 
        8: "Handy", 
        16: "Hobby", 
        18: "Mail", 
        19: "Nachrichten", 
        20: "OctoGate", 
        11: "Onlineshopping", 
        21: "Religion", 
        24: "Soziale Netzwerke", 
        14: "Spiele", 
        23: "Suchmaschinen", 
        10: "Viren Spyware Dialer", 
        1: "Werbung", 
        22: "Wissenschaft"
    },

    examAction:  {
        0: "angemeldet",
        1: "abgemeldet",
        2: "gestartet",
        3: "beendet",
        4: "eingesammelt",
        5: "ausgeteilt"
    },

    profileTypeMap: {
        1: "Lehrer",
        2: "Schüler",
        3: "Klassenarbeit"
    },

    profileTypeByVariantMap: {
        1: "teachers",
        2: "students",
        3: "exams"
    },

    opsiActions: {
        1: "setup",
        2: "update",
        3: "uninstall",
        0: "keine",
        5: "einmalig",
        4: "immer",
        6: "custom"
    },
    
    opsiStatus: {
        0: "unbekannt",
        1: "installiert",
        2: "nicht installiert",
    },

    opsiActionResult: {
        0: "unbekannt",
        1: "erfolgreich",
        2: "fehlerhaft"
    },

    opsiInstallAction: {
        0: "keine",
        1: "wird installiert",
        2: "wird deinstalliert",
        3: "wird aktualisiert",
        4: "wird re-installiert",
    },
}

export default Masterdata
