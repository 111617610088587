import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-info-jobs>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Tasks werden aktualisiert...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>

      <div class="row" if={parent.nodata}>
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">Der Job wurde bereits ausgeführt und gelöscht.</span>
        </div>
      </div>

      <table id="{opts.prefix}-modal-table" style="width: 100%;" class="table table-striped table-hover">
      </table>

    </yield>
  </paedml-modal>
    
    <script>
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import moment from 'moment'

      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.tasks = []
      this.table = null

      this.on('update', () => {
        // console.debug('update', this.tasks)
        this.loading = false
      })

      this.on('rowMappingRequested', cols => {
        // console.debug('parent', this.parent)
        this.nodata = false
        // console.debug('mapping', cols[0])
        this.loading = true
        this.api.getTasksByJobId(cols[0]).then(rsp => {
          this.loading = false
          const [tasks, status] = rsp
          if (status.code === 404 || tasks.length === 0) {
            this.nodata = true
            this.update()
            return
          }
          // console.debug(tasks)
          this.tasks = tasks.map(t => ({ ...t, status: Masterdata.taskStatus[t.status]}))

          const selector = `#${opts.prefix}-modal-table`
          this.table = $(selector).DataTable( {
            data: this.tasks,
            columns: [
              { data: 'id', title: 'TaskID' },
              { data: 'title', title: 'Titel' },
              { data: 'status', title: 'Status' },
              // { data: 'runCount', title: 'runCount' }
            ],
            paging: false,
            // scrollY: 400,
            // select: true
            info: false,
            searching: false
          } )

          const tableData = this.table.data()

          $('tbody tr', selector).each((i,e) => {
            $(e).tooltip({
              title: this.formatTaskInfo(tableData[i]),
              placement: 'top',
              trigger: 'click focus hover',
              delay: { "show": 1000, "hide": 10 }
            })
          })

          this.update()
        })
      })

      this.on('hide', () => {
        if (this.table) this.table.destroy()
        this.table = null
        if (this.nodata) {
          this.nodata = false
          this.parent.trigger('reloadTable')
        }
      })

      this.formatTaskInfo = t => {
        const lastRunTime = t.lastRunTime ? moment(t.lastRunTime).fromNow() : 'nie'
        return `Priorität: ${t.priority}, Anzahl Durchläufe: ${t.runCount}, Letzter Durchlauf Am: ${lastRunTime}, Anzahl Fehler: ${t.errorCount}`
      }

      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }


    </script>
</paedml-modal-info-jobs>
