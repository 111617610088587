<paedml-modal>
  <div id="{prefix}-modal" tabindex="-1" role="dialog" aria-labelledby="{prefix}-modal-label" aria-hidden="true" class="modal fade text-left" data-backdrop="static">
    <div role="document" class="modal-dialog {opts.large ? 'modal-lg' : ''} {opts.extra ? 'extra' : ''}">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="{prefix}-modal-label" class="modal-title">{opts.heading}</h5>
          <button type="button" data-dismiss="modal" aria-label="Close" class="close"><span aria-hidden="true">×</span></button>
        </div>

        <div class="modal-body">
          <form ref="form" class="form-validate" autocomplete="off">
            <yield from="fields"></yield>
            <div class="line"><span></span></div>

            <div show="{numSelectedRows > 1 && !opts.hidemulti}" class="container" style="padding:0px" id="{prefix}-modal-hinweis-container">
              <div class="form-group row">  
                <div class="col" if="{numSelectedAdjusted <= 30 && numSelectedAdjusted > 1}">Sie haben <span style="color:#FF9F1C; font-weight: bold;"> mehrere ({numSelectedAdjusted}) </span> {patient.plural} ausgew&auml;hlt.</div>
                <div class="col text-danger" if="{numSelectedAdjusted >  30}">Sie haben <span style="color:#dc3545; font-weight: bold;"> ungewöhnlich viele ({numSelectedAdjusted}) </span> {patient.plural} ausgew&auml;hlt.<br/><br/>
                  Bitte bestätigen Sie, dass es Ihrer Intention entspricht.
                  <paedml-form-input name="massSelectionOK" placeholder="Ich bin mir dessen bewusst!"
                    type="checkbox" revlut="jaNein" handlechange={update}
                    />
                </div>
                <div if="{numSelectedAdjusted > 1}" class="col-sm-12">Entfernen Sie gegebenenfalls unerw&uuml;nschte {patient.plural} aus der Liste:</div>                
              </div>
              <div ref="tip" class="col px-0" style="max-height: 7.9rem; overflow: auto;">
                <input ref="ti" type="text" id="{prefix}-modal-hinweis-selection" value="" data-role="tagsinput">
              </div>
              <div class="line"><span></span></div>

            </div>
            <div class="form-group text-center">
              <button if={opts.cancelbutton || !opts.okbutton} type="button" data-dismiss="modal" class="btn btn-secondary pull-left">Abbrechen</button>
              <button if={opts.okbutton} type="button" data-dismiss="modal" class="btn btn-primary">{opts.okbutton}</button>
              <button if={!opts.okbutton} disabled={!okcondition() || !massActionOK()} ref="sb" data-style="expand-left" type="submit" class="btn btn-{buttonType ? buttonType : 'primary'} pull-right">{opName}</button>
            </div>
          </form>
        </div>                     
      </div>
    </div>
  </div>

  <script>
    import * as R from 'ramda'

    this.prefix = this.opts.prefix; // for various id generation
    this.opName = this.opts.opname; // submit button text
    this.buttonType = this.opts.submitbuttontype
    this.okcondition = this.opts.okcondition || function() {return true}
    this.submitHandler = this.opts.submitfunc;
    this.edit = this.opts.edit ? true : false

    this.patient = this.opts.patient || {
      singular: 'Benutzerkonto',
      plural: 'Benutzerkonten',
    }

    // support for multiple selections
    this.getSelectedRows = this.opts.selectedrowsfunc || function() { return [] };
    this.numSelectedRows = 0;
    this.idCol = this.opts.idcol || 0
    this.idTextCol = this.opts.idtextcol || 1

    this.fastselects = []

    this.massActionOK = () => {
      if (this.numSelectedAdjusted <= 30) return true
      return $('input[name=massSelectionOK]:checked', this.refs.form).length > 0
    }

    this.preventAll = e => {
      e.preventDefault()
      e.stopPropagation()
    }

    this.on('mount', function() {
      const self = this

      const ti = $(this.refs.ti);
      ti.tagsinput({
        itemValue: 'id', itemText: 'text'
      })

      this.validate = $(this.refs.form).validate({
            errorElement: "div",
            errorClass: 'is-invalid',
            validClass: 'is-valid',
            ignore: ':hidden:not(.summernote),.note-editable.card-block',
            errorPlacement: function (error, element) {
                // Add the `invalid-feedback` class to the error element
                error.addClass("invalid-feedback");
                if (element.prop("type") === "checkbox") {
                    error.insertAfter(element.siblings("label"));
                } 
                else {
                    error.insertAfter(element.parent());
                }
            },
            submitHandler: function(form, e) {
              e.preventDefault();
              e.stopPropagation();

              const l = Ladda.create(self.refs.sb);
              l.start();

              const userInput = { }

              if (self.numSelectedRows && $(self.refs.ti)) {
                const val = $(self.refs.ti).val()
                userInput.ids = val ? val.split(',') : []
                userInput.idTexts = $('span.tag', self.refs.tip).map((i,v) => $(v).text()).toArray()
              }

              $('.form-control,.custom-control-input', form).each(function(i, e) {
                const fieldName = $(e).attr('name');
                if (fieldName) {
                  if (e.type === 'checkbox') {
                    userInput[fieldName] = e.checked ? true : false;
                  } else {
                    userInput[fieldName] = $(e).val()
                  }
                }
              })
              
              self.submitHandler(userInput)
                .then(function(successMessage) {
                  l.stop()
                  form.reset();
                  $('#' + self.prefix + '-modal').modal('hide');
                  $('.modal-backdrop').remove()
                  Messenger().post({
                    message: successMessage,
                    type: 'success'
                  })
                }, function(errorMessage) {
                  l.stop()
                  $('#' + self.prefix + '-modal').modal('hide');
                  $('.modal-backdrop').remove()
                  if (errorMessage instanceof Array && errorMessage.length === 2)  {
                    Messenger().post({
                      message: errorMessage[1],
                      type: 'error'
                    })
                    Messenger().post({
                      message: errorMessage[0],
                      type: 'success'
                    })
                  } else {
                    Messenger().post({
                      message: errorMessage,
                      type: 'error'
                    })
                  }
                })
              return false;
            }
        });

      const updateModel = (key, value) => {
        self.parent.data[key] = value
      }
      this.on('updateModel', updateModel)

      const updateModal = (resetFastselect) => {
        const data = self.parent.data || {}
        const form = self.refs.form
        for (let k in data) {
          if (data[k] === true || data[k] === false) {
            $('.custom-control-input[name="' + k + '"]', form).prop('checked', data[k])
          } else {
            $('.form-control[name="' + k + '"]', form).val(data[k])
          }
        }

        if (resetFastselect) reinitFastselect()

        self.update()
      }

      const formToData = () => {
        const form = self.refs.form
        const userInput = self.parent.data || {}
        $('.form-control,.custom-control-input', form).each(function(i, e) {
          const fieldName = $(e).attr('name');
          if (fieldName) {
            if (e.type === 'checkbox') {
              userInput[fieldName] = e.checked ? true : false;
            } else {
              userInput[fieldName] = $(e).val()
            }
          }
        })
      }

      const reinitFastselect = () => {
        (self.fastselects || []).forEach(v => {
          v.destroy()
        })
        self.fastselects = []

        $('select.form-control.multipleSelect').each((i,elem) => {
          const fs = $(elem).fastselect({
            onItemSelect: function(e) {
              if (self.validate) {
                self.validate.element(elem)
              }
            }
          }).data('fastselect')
          self.fastselects.push(fs)
        })

        const st = $('span.tag', this.refs.tip)
        self.numSelectedAdjusted = st.length

        $('input', self.refs.tip).prop('disabled', false)
          .off('change')
          .on('change', e => {
            self.numSelectedAdjusted = $('span.tag', self.refs.tip).length
            if (self.numSelectedAdjusted === 1) $('input', self.refs.tip).prop('disabled', true)
            self.update()
          })
          .off('keydown')
          .on('keydown', this.preventAll)
      }

      this.on('formToData', formToData)
      
      this.on('updateModal', updateModal)

      this.on('updateModelAndModal', (key, value) => { updateModel(key, value); updateModal()})

      this.on('reinitFastselect', reinitFastselect)

      this.on('updateButton', (buttonText, buttonType) => {
        this.opName = buttonText
        this.buttonType = buttonType
        this.update()
      })

      $(this.root).on('show.bs.modal', function(e) {
        const form = self.refs.form

        ti.tagsinput('removeAll')
        const selectedRows = self.getSelectedRows()
        self.numSelectedRows = selectedRows.length
        R.forEach(row => {
          ti.tagsinput('add', {
            id: row[self.idCol],
            text: row[self.idTextCol]
          });
        }, selectedRows)

        self.parent.trigger('dataRequested', selectedRows, self);
        if (self.edit && selectedRows && selectedRows.length === 1) {
          self.parent.trigger('rowMappingRequested', selectedRows[0], self);
        }

        const data = self.parent.data || {}
        for (let k in data) {
          if (data[k] === true || data[k] === false) {
            $('.custom-control-input[name="' + k + '"]', form).prop('checked', data[k])
          } else {
            $('.form-control[name="' + k + '"]', form).val(data[k])
          }
        }

        reinitFastselect()

        self.parent.trigger('validate', form, self.validate)

        self.update()
      })

      $(this.root).on('shown.bs.modal', function(e) {
        self.parent.trigger('shown')
      })

      self.on('refreshData', function() {
        const data = self.parent.data || {}
        const form = self.refs.form
        for (let k in data) {
          if (data[k] === true || data[k] === false) {
            $('.custom-control-input[name="' + k + '"]', form).prop('checked', data[k])
          } else {
            $('.form-control[name="' + k + '"]', form).val(data[k])
          }
        }
        self.update()
      })

      $(this.root).on('hide.bs.modal', function(e) {
        self.parent.trigger('hide');

        // reset all form elements
        const form = self.refs.form
        form.reset()
        self.validate.resetForm()
        $('.form-control', form).removeClass('is-invalid is-valid')
        if (ti) {
          ti.tagsinput('removeAll')
        }

        for(let i=0; i < self.fastselects.length; ++i) {
          self.fastselects[i].destroy();
        }
        self.fastselects = []

        self.parent.data = {}
      })
    })
  </script>
</paedml-modal>
