import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './bookmarks/paedml-modal-cleanup-tags.tag'

<paedml-card-tags>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>

    <virtual if={canDelete()}>
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Tags(s) löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Tags(s) löschen" prefix="{prefix}-delete" 
        idcol="0" idtextcol="1"
        patient="{{singular: 'Tag', plural: 'Tags', as: 'den'}}"
        selectedrowsfunc={getSelectedRows} api={restApi} doredirect={false} />
    </virtual>

    <virtual if={selectedNone() && isPermitted('unused')}>
      <paedml-icon-modal icon="fas-link-slash" target="#{prefix}-sync-modal" title="Ungenutzte Tags löschen" />
      <paedml-modal-cleanup-tags prefix="{prefix}-sync" selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Ungenutzte Tags löschen" opname="OK" api={restApi} />
    </virtual>

  </paedml-card>
</paedml-card-tags>
