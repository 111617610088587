import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-info-klasse>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="row">
        <div class="col-sm-4">
          <paedml-form-input name="schoolYear" col="3" label="Schuljahr" readonly />
        </div>
        <div class="col-sm-4">
          <paedml-form-input name="schoolTypeId" col="1" label="Schulart" readonly />
        </div>
        <div class="col-sm-4">
          <paedml-form-input name="name" col="2" label="Klasse" readonly />
        </div>
      </div>

      <paedml-form-input name="shareFolder" col="5" label="Tauschlaufwerk" readonly />

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }

    </script>
</paedml-modal-info-klasse>
