<paedml-tags-input>
    <virtual>
        <div class="line"></div>
        <div class="form-group {opts.fgclass}" title={opts.title || opts.placeholder || opts.label}>
            <label if="{opts.label}">{opts.label}</label>

             <div class="col px-0" style="max-height: 7.9rem; overflow: auto;">
                <select 
                    id={opts.inputid} name={opts.name} multiple placeholder={opts.label}
                    onchange={handleSelectionChange}
                    ref="tagsInput" data-role="tagsinput">
                </select>
            </div>

        </div>
    </virtual>

    <script>
        import {genDocId} from '../../util/crypto-util'
        import * as R from 'ramda'

        this.handleChange = this.opts.handlechange

        this.handleSelectionChange = e => {
            const tagsInput = $(this.refs.tagsInput);
            this.handleAndNotify(tagsInput.val(), e)
        }

        this.handleAndNotify = (val, e) => {
            if (this.handleChange) {
                const ret = this.handleChange(val, this, e)
                this.parent.trigger('updateModal', ret)
            }
        }

        this.inputid = this.opts.inputid ? this.opts.inputid : genDocId();

        this.on('update', () => {
            if(this.opts.values === undefined){
                return
            }
            const tagsInput = $(this.refs.tagsInput);
            R.forEach(row => {
                tagsInput.tagsinput('add', row, {preventPost: true});
            }, this.opts.values)
        })

        this.on('mount', () => {
            const tagsInput = $(this.refs.tagsInput);
            tagsInput.tagsinput()
            tagsInput.on('itemAdded', this.handleSelectionChange)
            tagsInput.on('itemRemoved', this.handleSelectionChange)
        })

    </script>
</paedml-tags-input>
