import { dashboard } from './dashboard'
import { rechteverwaltung } from './rechteverwaltung'
import { benutzerverwaltung } from './benutzerverwaltung'
import { jobverwaltung } from './jobverwaltung'
import { importverwaltung } from './importverwaltung'
import { meins } from './meins'
import { klassenarbeiten } from './klassenarbeiten'
import { versetzung } from './versetzung'
import { raumverwaltung } from './raumverwaltung'
import { firewallverwaltung } from './firewall'
import { wartung } from './wartung'
import { quotaverwaltung } from './quotaverwaltung'
import { cloudverwaltung } from './cloudverwaltung'
import { moodleverwaltung } from './moodleverwaltung'
import { profilverwaltung } from './profilverwaltung'
import { singlelogin } from './singlelogin'
import { software } from './software'
import { bookmarks } from './bookmarks'

import * as R from 'ramda'

const pages = {
  dashboard,

  benutzerverwaltung,
  cloudverwaltung,
  firewallverwaltung,
  importverwaltung,
  jobverwaltung,
  klassenarbeiten,
  meins,
  moodleverwaltung,
  profilverwaltung,
  quotaverwaltung,
  raumverwaltung,
  rechteverwaltung,
  versetzung,
  wartung,
  singlelogin,
  software,
  bookmarks
}

const pagesByUrl = {}
const cardsByUrl = {}

R.forEachObjIndexed(page => {
  pagesByUrl[`${page.route}`] = page.cards

  page.cards.forEach(card => {
      cardsByUrl[`${page.route}/${card.route}`] = card
  })
})(pages)

export default pages
export {pagesByUrl, cardsByUrl}
