import './paedml-card.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './bookmarks/paedml-modal-createoredit-bookmark.tag'
import './bookmarks/paedml-modal-info-bookmark.tag'
import './bookmarks/paedml-modal-cleanup-tags.tag'
import './bookmarks/paedml-modal-edit-tags.tag'

<paedml-card-bookmarks>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>

    <virtual if={selectedNone() && canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Link anlegen" />
      <paedml-modal-createoredit-bookmark prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Link anlegen" opname="Link anlegen" api={restApi} variant={parent.variant}/>
    </virtual>

    <virtual if={canSeeInfo()}>
      <paedml-icon-modal icon="fas-qrcode" target="#{prefix}-see-modal" title="Link-Info ansehen" />
      <paedml-modal-info-bookmark prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Info" opname="OK" api={restApi} variant={parent.variant}/>
    </virtual>

    <virtual if={canEdit()}>
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Link bearbeiten" />
      <paedml-modal-createoredit-bookmark prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Link bearbeiten" opname="Änderung speichern" api={restApi} variant={parent.variant}/>
    </virtual> 

    <virtual if={canDelete()}>
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Link(s) löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Link(s) löschen" prefix="{prefix}-delete" 
        idcol="0" idtextcol="2"
        patient="{{singular: 'Link', plural: 'Links', as: 'den'}}"
        selectedrowsfunc={getSelectedRows} api={restApi} doredirect={false} variant={parent.variant}/>
    </virtual>

  </paedml-card>
  <script>
    import API from '../api'
    import * as R from 'ramda'

    this.opname = this.opts.opname
    this.api = this.opts.api
    this.variant = opts.config.custom.variant

  </script>
</paedml-card-bookmarks>
