<paedml-modal-edit-tags>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={true}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient} large={true}
                cancelbutton={true} okcondition={okcondition}
                >

    <yield to="fields">
      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
          <p class="my-auto">Liste der Tags wird aktualisiert...</p>
          <div class="sk-wave">
            <div class="sk-rect sk-rect1"></div>
            <div class="sk-rect sk-rect2"></div>
            <div class="sk-rect sk-rect3"></div>
            <div class="sk-rect sk-rect4"></div>
            <div class="sk-rect sk-rect5"></div>
          </div>
        </div>

      <div class="d-flex" show={!parent.loading}>
        <div style="width: 45%;">
          <table id="{opts.prefix}-source-modal-table" style="width: 45%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningSource} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningSource}</strong> </span>
          </div>
        </div>
        <div style="width: 10%;">
          <div class="d-flex justify-content-center pt-5 mt-5">
            <paedml-icon icon="fas-long-arrow-alt-right" inv={true} title="Ausgewählte(n) Tags löschen" onclick={parent.moveSource} />
          </div>
          <div class="d-flex justify-content-center">
            <paedml-icon icon="fas-long-arrow-alt-left" inv={true} title="Ausgewählte(n) Tags nicht löschen" onclick={parent.moveTarget} />
          </div>
        </div>
        <div style="width: 45%;">
          <table id="{opts.prefix}-target-modal-table" style="width: 100%;" class="table table-striped table-hover"></table>
          <div if={parent.data.warningTarget} class="mt-2 mr-1 p-2 border border-danger">
            <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" /> <strong class="text-danger pl-1 pt-2">{parent.data.warningTarget}</strong> </span>
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>

  <script>
    import * as i18n from '../../config/i18n/german'
    import * as R from 'ramda'
    import { toTrueMap } from '../../util/mapping-util'
    import API from '../../api'
    import { patientFormatter, handleSubmitEdit } from '../../util/api-util'
    import Session from '../../store/session'
    
    this.op = opts.op
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.target = opts.target
    this.data = {
    }
    this.api = this.opts.api
    this.patient = opts.patient || {singular: 'Tag', plural: 'Tags'}
    
    this.okcondition = () => !(this.data.warningSource || this.data.warningTarget)

    this.on('update', () => {
      this.loading = false
    })

    this.moveRows = (from, to) => {
      const selected = from.rows( { selected: true } )
      const selectedRows = selected.data()
      const targetRow = to.row
      selectedRows.each((e,i) => {
        targetRow.add(e)
      })
      selected.remove()

      from.draw()
      to.draw()
      $('input[type=search]').val('').trigger('keyup')
      $('select[ref=input]').val(' ').trigger('change')
    }

    this.moveSource = () => {
      this.moveRows(this.tableSource, this.tableTarget)
    }

    this.moveTarget = () => {
      this.moveRows(this.tableTarget, this.tableSource)
    }

    this.getTableSource = () => this.tableSource
    this.getTableTarget = () => this.tableTarget

    this.refreshSource = (existing) => {
      this.loading = true
      API.tags.getAll().then(rsp => {
        this.loading = false
        const [source, status] = rsp

const sourceList = []
         source.forEach(t => {
         sourceList.push({id:t, tag:t})
        })
        const selector = `#${opts.prefix}-source-modal-table`
        this.tableSource = $(selector).DataTable( {
          data: sourceList,
          columns: [
            { data: 'tag', title: 'Tag' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableSource()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningSource = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningSource = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningSource = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningSource = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        const sourceNode = $(selector + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
        if (this.variant !== 'members')
          sourceNode.html('<strong>Vorhandene Tags</strong>')
        $(selector + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-7 col-xl-7')

        const selectorTarget = `#${opts.prefix}-target-modal-table`
        const targetList = []
        this.tableTarget = $(selectorTarget).DataTable( {
          data: targetList,
          columns: [
            { data: 'tag', title: 'Tag' },
          ],
          paging: false,
          scrollY: 400,
          lengthChange: false,
          select: {
              style: 'multi+shift'
          },
          info: true,
          oLanguage: i18n.short,
          infoCallback: ( settings, start, end, max, total, pre ) => {
            const dt = this.getTableTarget()
            if (dt) {
              const selected = dt.rows({selected: true}).data().length
              const selectionOnThisPage = dt.rows({selected: true, page:'current'}).data().length
              const hidden = selected - selectionOnThisPage
              if (hidden !== 0) {
                if (hidden === 1) {
                  this.data.warningTarget = 'Aktueller Filter verdeckt einen ausgewählten Eintrag!'
                } else {
                  this.data.warningTarget = `Aktueller Filter verdeckt ${hidden} ausgewählte Einträge!`
                }
              } else {
                if (settings.aiDisplay.length !== settings.aiDisplayMaster.length) {
                  this.data.warningTarget = 'Bitte setzen Sie den Filter zurück, um die Änderungen übernehmen zu können'
                } else {
                  this.data.warningTarget = ''
                }
              }
              this.update()
            }
            return pre
          }
        } )
        const targetNode = $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(1)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-5')
        if (this.variant !== 'members')
          targetNode.html('<strong>Tags die gelöscht werden</strong>')
        $(selectorTarget + '_wrapper > div:nth-child(1) > div:nth-child(2)')
          .removeClass('col-md-6')
          .addClass('col-md-12 col-lg-6 col-xl-7')
        
        this.update()
      })
    }

    this.on('hide', () => {
      if (this.tableSource) this.tableSource.destroy()
      this.tableSource = null
      if (this.tableTarget) this.tableTarget.destroy()
      this.tableTarget = null
    })

    this.on('dataRequested', cols => {
      this.loading = true
      this.refreshSource()
    })

    this.submitHandler = formData => {
      const tags = []
        this.tableTarget.data().each(e => {
          tags.push(e.id)
        })
      
      return API.tags.delete(tags).then(rsp => {
          return handleSubmitEdit(this, rsp, undefined, undefined, true)
        })
    }

  </script>
</paedml-modal-edit-tags>
