import * as R from 'ramda'

export const autoMapRowToData = function(target, cols) {
  const data = {}
  $('paedml-form-input[name][col]', target.root).each((i, tag) => {
    const jqTag = $(tag)
    const key = jqTag.attr('name')
    const value = cols[jqTag.attr('col')]
    
    const revlut = jqTag.attr('revlut')
    const multi = jqTag.attr('multiple')
    data[key] = resolve(revlut, multi, value)
  })
  Object.assign(target.data, data)
}

const resolve = (revlut, multiple, value) => {
  if (revlut) {
    if (multiple) return resolveToId(value.split(', '), Session.lut[revlut])
    return Session.lut[revlut][value]
  }
  return value
}

const resolveToId = function(list, lut) {
  return list.map(function(v) {
    return lut[v]
  })
}

export const hideAllModal = () => $('.modal').modal('hide')

/**
 * @param  {object} patient e.g. {singular: 'Benutzerkonto', plural: 'Benutzerkonten'}
 * @param  {string} action e.g. 'gelöscht'
 */
export const patientFormatter = (patient, action) => (idLut, success) => ids => {
  const text = success ? {singular: 'wurde', plural: 'wurden', op: 'erfolgreich ' + action } 
                       : {singular: 'konnte', plural: 'konnten', op: 'nicht ' + action + ' werden' } 
  const fuer = patient.for ? patient.for + ' ' : ''
  if (ids.length === 1) {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" ${text.singular} ${text.op}`
  } else if (ids.length === 2) {
    return `${patient.plural} ${fuer}"${idLut[ids[0]]}" und "${idLut[ids[1]]}" ${text.plural} ${text.op}`
  } else {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" und ${ids.length-1} weitere ${patient.plural} ${text.plural} ${text.op}`
  } 
}

/**
 * @param  {object} patient e.g. {singular: 'Benutzerkonto', plural: 'Benutzerkonten'}
 * @param  {string} action e.g. 'gelöscht'
 */
export const patientSingularFormatter = (patient, action) => (idLut, success) => ids => {
  const text = success ? {singular: 'wurde', plural: 'wurde', op: 'erfolgreich ' + action } 
                       : {singular: 'konnte', plural: 'konnte', op: 'nicht ' + action + ' werden' } 
  const fuer = patient.for ? patient.for + ' ' : ''
  if (ids.length === 1) {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" ${text.singular} ${text.op}`
  } else if (ids.length === 2) {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" und "${idLut[ids[1]]}" ${text.singular} ${text.op}`
  } else {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" und ${ids.length-1} weitere ${text.singular} ${text.op}`
  } 
}

/**
 * @param  {object} patient e.g. {singular: 'Benutzerkonto', plural: 'Benutzerkonten'}
 * @param  {string} action e.g. 'gelöscht'
 */
export const patientFormatterFuture = (patientFnOrVal, action) => (idLut, success) => ids => {
  const text = success ? {singular: 'wird', plural: 'werden', op: action } 
                       : {singular: 'kann', plural: 'können', op: 'nicht ' + action + ' werden' } 
  const patient = R.type(patientFnOrVal)  === 'Function' ? patientFnOrVal() : patientFnOrVal
  const fuer = patient.for ? patient.for + ' ' : ''
  if (ids.length === 1) {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" ${text.singular} ${text.op}`
  } else if (ids.length === 2) {
    return `${patient.plural} ${fuer}"${idLut[ids[0]]}" und "${idLut[ids[1]]}" ${text.plural} ${text.op}`
  } else {
    return `${patient.singular} ${fuer}"${idLut[ids[0]]}" und ${ids.length-1} weitere ${patient.plural} ${text.plural} ${text.op}`
  } 
}

export const multiSubmitHandler = (ctx, opFuncFnOrVal, successFormatter, failureFormatter = successFormatter, triggerRelogin = false) => formData => {
  if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

  const opFunc = R.type(opFuncFnOrVal === 'Function') ? opFuncFnOrVal() : opFuncFnOrVal
  const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
  const messageSuccess = successFormatter(idLut, true)
  const messageFailure = failureFormatter(idLut, false)

  return opFunc(formData.ids).then(rsp => {
    const [resultIds, status] = rsp
    if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server.')

    if (! resultIds) return Promise.resolve('Die Anfrage wurde erfolgreich an den Server übermittelt.')

    const {succeeded, failed} = resultIds
    if (failed.length) {
      if (succeeded.length) {
        // partly success
        const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
        // remove succeeded entries
        $('span.tag', this.refs.tip).each(function(i,e) { 
          if (sLut[$(e).text()]) $('span', e).click()
        })

        ctx.parent.trigger('reloadTable')
        return Promise.reject([messageSuccess(succeeded), messageFailure(failed)])
      }
        ctx.parent.trigger('reloadTable')
        return Promise.reject(messageFailure(failed))
    }

    ctx.parent.trigger('reloadTable')
    if (triggerRelogin) window.request.relogin()
    return messageSuccess(succeeded)
  })

}
