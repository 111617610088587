import '../paedml-modal.tag'
import '../paedml-form-input.tag'
import '../components/paedml-tags-input.tag'

<paedml-modal-createoredit-bookmark>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
        <div class="paedml-input-group">
            <div class="row">
                <div class="col-sm-12">
                    <paedml-form-input name="name" col="2" label="Titel"
                        required data-msg="Bitte geben Sie einen Titel ein"
                        data-rule-alphanumchar 
                        data-rule-maxlength="64" data-msg-maxlength="Der Titel darf nicht mehr als 64 Zeichen enthalten!"
                    />
                </div>
                <div class="col-sm-12">
                    <paedml-form-input name="url" col="3" label="Url"
                        required data-msg="Bitte geben Sie eine Url ein"
                        data-rule-url
                    />
                </div>
                 <div class="col-sm-12">
                    <paedml-form-input name="description" col="5" label="Beschreibung"
                        data-msg="Bitte geben Sie eine Beschreibung ein"
                    />
                </div>
            </div>
        </div>
        <div class="paedml-input-group" if={parent.variant === 'bookmarks'}>
            <paedml-form-input
                type="select" multiple
                name="schoolTypes" col="9" revlut="schularten" placeholder="Schulart(en)" label="Schulart(en)"
                required data-msg="Bitte wählen Sie mindestens eine Schulart aus!" 
                values={parent.session.map.schularten}
            />
        </div>
        <div class="paedml-input-group">
            <paedml-tags-input
                name="tags" placeholder="Tag(s)" label="Tag(s)"
                values={parent.data.tags}
                handlechange={parent.handleTagsChange}
                />
        </div>
    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../../util/modal-util'
      import store from '../../store'
      import { client, handleSubmitCreateOrEdit } from '../../util/api-util'
      import * as mapper from '../../mapper'
      import * as R from 'ramda'
      import Session from '../../store/session'
      import Masterdata from '../../config/masterdata'
      import API from '../../api'

      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.data.tags = []
      this.api = this.opts.api
      this.session = Session
      this.variant = opts.variant
     
      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.data.tags = cols[6]
      })

      this.handleTagsChange = tags => {
        this.data.tags = tags
      }

    this.submitHandler = formData => {
        formData.tags = this.data.tags
        return handleSubmitCreateOrEdit(this, data => `Link "${data.name}"`, data => `Link "${data.name}"`, false, true)(formData)
    }

    </script>
</paedml-modal-createoredit-bookmark>
