import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-info-schueler>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
   
    <yield to="fields">

      <div class="row">
        <div class="col-sm-6">
          <paedml-form-input name="givenName" col="3" label="Vorname" readonly />
        </div>
        <div class="col-sm-6">
          <paedml-form-input name="surname" col="2" label="Nachname" readonly />
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <paedml-form-input name="userName" col="1" label="Benutzername" readonly />
        </div>
        <div class="col-sm-6">
            <paedml-form-input name="klassenbezeichnung2" col="14" label="Klasse" readonly />
        </div>
      </div>

      <paedml-form-input name="externalId" col="11" label="Externe ID" readonly />
      <paedml-form-input name="homeDirectory" col="9" label="Homelaufwerk" readonly />

      <paedml-form-input show={parent.data.email} name="email" col="10" label="E-Mail" readonly />
      <paedml-form-input show={parent.data.comments} name="comments" col="15" label="Notizen" readonly />

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
      })

      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }

    </script>
</paedml-modal-info-schueler>
