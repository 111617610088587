import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-createoredit-lehrer>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler} okcondition="{() => !isChecking && !isExtenalIdChecking}">
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="mainSchoolType" col="5" label="Basis-Schulart" ref="mainSchoolType"
              type="select" values={parent.session.map.schulartenLong} revlut="schularten"
              required data-msg="Bitte wählen Sie die Basis-Schulart aus"
              handlechange={parent.handleMainSchoolTypeChange} fgclass="pb-2 mt-1"
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="schoolTypes" col="6" revlut="schularten" label="Zugeordnete Schulart(en)" placeholder="Zugeordnete Schulart(en) inkl. Basis-Schulart"
              type="select" multiple
              required data-msg="Bitte wählen Sie mindestens eine Schulart aus!" 
              values={parent.session.map.schularten}
              handlechange={parent.handleSchoolTypeChange}
            />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="surname" col="2" label="Nachname"
              required data-msg="Bitte geben Sie den Nachnamen ein"
              data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Nachnamen ein."
              handlechange={parent.handleNameChange}
            />
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="givenName" col="3" label="Vorname"
                required data-msg="Bitte geben Sie den Vornamen ein"
                data-rule-personname data-msg-personname="Bitte geben Sie einen gültigen Vornamen ein."
                handlechange={parent.handleNameChange}
            />
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <paedml-form-input name="userName" col="1" label="Benutzername" placeholder="Falls leer, dann wird er automatisch generiert"
              readonly={edit} 
              data-rule-username data-msg-username="Erlaubt sind: Buchstaben und Ziffern. Optional kann ein &quot;.&quot;, &quot;-&quot; oder &quot;_&quot; als Trennzeichen verwendet werden, jedoch nicht am Anfang und Ende."
              handlechange={parent.handleUserNameChange} changemode="keyup"
              verifychange={parent.verifyUsernameChanged}
              data-rule-check="userName" data-msg-check="Benutzername bereits vorhanden!"
              maxlength="19" data-rule-maxlength="18" data-msg-maxlength="Der Benutzername darf nicht mehr als 18 Zeichen enthalten!" 
            />
            <svg if={parent.isChecking} class="paedml-icon-overlay fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
          <div class="col-sm-6">
            <paedml-form-input name="initials" col="4" label="Kürzel"
              data-rule-alphanum data-msg-alphanum="Nur Buchstaben und Zahlen sind erlaubt!"
              data-rule-maxlength="6" data-msg-maxlength="Erlaubt sind maximal 6 Zeichen."
            />
          </div>
        </div>

        <div class="row" show={!parent.edit && parent.userNameCorrected}>
          <div class="col-sm-12">
            <paedml-icon icon="far-edit" inv={true} />
            <span class="text-small">Benutzername wurde automatisch angepasst. Bitte nach Wunsch bearbeiten.</span>
          </div>
        </div>
      </div>

      <div class="paedml-input-group" if={!parent.edit}>
        <paedml-form-input-pw if={!parent.edit}
          uselabel={true}
          placeholder='Falls leer, dann wird das Basiskennwort verwendet'
          minLength={parent.getPasswordLength()}
          passwordoption={parent.getPasswordPolicy()} />
      </div>

      <div class="paedml-input-group">
        <div class="row">
         <div class="col-sm-8">
            <paedml-form-input name="externalId" col="10" label="Externe ID" 
              required={parent.data.externalIdVerified} data-msg="Bitte geben Sie eine Externe ID ein"
              handlechange={parent.handleExternalIdChange} changemode="keyup" 
              data-rule-check="externalId" data-msg-check="Externe ID bereits vorhanden!" 
              maxlength="64" data-rule-maxlength="64" data-msg-maxlength="Die Externe ID darf nicht mehr als 64 Zeichen enthalten!"
              readonly={parent.canModifyExternalId()}
              />
            <svg if={parent.isExternalIdChecking} class="paedml-icon-overlay fullrotation">
              <use xlink:href="#far-question-circle" />
            </svg>
          </div>
          <div class="col-sm-4">
            <paedml-form-input name="externalIdVerified" col="11" label="ID verifiziert" fgclass="py-2"
             type="checkbox" revlut="jaNein" handlechange={parent.handleExternalIdChange} />
          </div>
        </div>
      </div>

      <div class="paedml-input-group">
        <paedml-form-input name="isDeactivated" col="7" placeholder="Benutzerkonto ist deaktiviert" fgclass="py-2"
            type="checkbox" revlut="jaNein"
        />

        <paedml-form-input name="homeDirectory" col="8" label="Homelaufwerk"
          show={edit} readonly
        />

      </div>

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="email" col="9" label="E-Mail" 
            data-rule-emailextra data-msg-emailextra="Bitte geben Sie eine gültige E-Mailadresse ein."
            />
          </div> 
        </div>
      </div>
      
      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="comments" col="12" label="Notizen" />
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client, userNameAvailable, clearUserNameAvailabilityCtx, handleSubmitCreateOrEditUser } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import API from '../api'

      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session
      this.resolved = false
      this.lastUserName = this.data.userName
      this.externalIdResolved = false

      this.userNameAvailable = userNameAvailable(this, 1000)
      this.userNameAvailableFast = userNameAvailable(this, 5)

      this.canModifyExternalId = () => {
        const { externalId, externalIdVerified } = this.data
        return this.edit && this.externalIdResolved && externalId && externalIdVerified
      }

      this.handleExternalIdChange = () => {
        
        const { externalId, externalIdVerified } = this.data
        
        this.isExternalIdChecking = true

        if(!externalId) {
          
          $('input[name=externalId]', this.root).data('externalId', true)
          $('input[name=externalId]', this.root).valid()
          this.isExternalIdChecking = false
          this.externalIdResolved = true
          
          this.update()
          return
        }

       API.externalId.available(this.id, externalId).then(available => {
          this.isExternalIdChecking = false

          $('input[name=externalId]', this.root).data('externalId', available)
          $('input[name=externalId]', this.root).valid()
          this.externalIdResolved = available
        
          this.update()
        })
      }

      this.handleUserNameChange = (userName, fast) => {
        this.lastUserName = userName
        if (!userName) {
          this.isChecking = false
          this.resolved = true
          this.update()
          return
        }

        $('input[name=userName]', this.root).data('userName', true)

        if (/^[A-Za-z0-9][-._A-Za-z0-9]{0,16}[A-Za-z0-9]$|^[A-Za-z0-9]$/.test(userName)) {
          this.isChecking = true
       
          if (fast === true) {
            this.userNameAvailableFast(userName)
          } else {
            this.userNameAvailable(userName)
          }
        }
      }

      this.verifyUsernameChanged = (userName) => {
        return this.lastUserName !== userName
      }

      this.handleNameChange = () => {
        if (this.edit) return
        this.userNameCorrected = false
        this.update()
        const {mainSchoolType, userName, surname, givenName} = this.data
        if (mainSchoolType && !userName && surname && givenName) {
          API.getUserNameFor.teacher(mainSchoolType, surname, givenName).then(rsp => {
            const [uname, status] = rsp
            const userName = uname.validUserName
            this.userNameCorrected = uname.wasCorrected
            if (!this.data.userName && status.success) {
              clearUserNameAvailabilityCtx(this)
              this.data.userName = userName
              $('input[name=userName]', this.root).val(userName);
              this.handleUserNameChange(userName, true)
            }
          })
        }
      }

      this.getPasswordLength = () => {
        const schulartSetting = Session.settings[this.data.mainSchoolType || 0]
        return schulartSetting['teacherPasswordLength']
      }

      this.getPasswordPolicy = () => {
        const schulartSetting = Session.settings[this.data.mainSchoolType || 0]
        return schulartSetting['teacherDefaultPasswordPolicy']
      }

      const convertingBooleans = R.mapObjIndexed(v => {
          if (v === 'true' || v === 'ja') return true
          if (v === 'false' || v === 'nein') return false
          return v
      })

      this.refreshPwSettings = () => {
        this.data.passwordPolicy = this.getPasswordPolicy()
        if (!this.edit) {
          const pwTag = this.tags['paedml-modal'].tags['paedml-form-input-pw']
          pwTag.trigger('refresh', { minPwLength: this.getPasswordLength() })
          $('input', pwTag.refs.pwMain.root).valid()
        }
      }

      // TODO: implement easier solution for update fields
      this.handleMainSchoolTypeChange = val => {
        if (!R.includes(this.data.mainSchoolType)(this.data.schoolTypes)) {
          this.data.schoolTypes.push(this.data.mainSchoolType)
        }
        $('select[name=mainSchoolType]').removeClass('is-invalid').addClass('is-valid')
        if (!this.edit) {
          this.handleNameChange()
          this.refreshPwSettings()
        }
        return true
      }

      // FIXME: revalidate element instead of changing
      this.handleSchoolTypeChange = (val, target) => {
        if (!R.includes(this.data.mainSchoolType)(val)) {
          this.data.mainSchoolType = ''
          $('select[name=mainSchoolType]').removeClass('is-valid').addClass('is-invalid')
        }
        if (!this.edit) {
          this.handleNameChange()
          this.refreshPwSettings()
        }
      }
 
      const firstOrEmptySelection = (name, map) => {
        if (this.data[name]) return
        const keys = R.keys(map)
        this.data[name] = keys.length === 1 ? keys[0] : ''
      }

      this.on('dataRequested', () => {
        $('input[name=externalId]', this.root).data('externalId', true)
        clearUserNameAvailabilityCtx(this)
        firstOrEmptySelection('mainSchoolType', Session.map.schularten)
        firstOrEmptySelection('schoolTypes', Session.map.schularten)
        
        if (this.data.mainSchoolType) {
          this.handleMainSchoolTypeChange(this.data.mainSchoolType)
        } else {
          this.refreshPwSettings()
        }
        this.handleExternalIdChange()
      })

      this.on('rowMappingRequested', cols => {
        autoMapRowToData(this, cols)
        this.id = cols[0]
      })

      this.submitHandler = handleSubmitCreateOrEditUser(this, "Lehrer*in")
    </script>
</paedml-modal-createoredit-lehrer>
