import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-edit-email>
  <paedml-modal prefix={opts.prefix} 
  heading={opts.heading} opname={opts.opname} edit={opts.edit} 
  selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
  submitfunc={submitHandler}>
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12">
            <paedml-form-input name="email" label="Moodle E-Mail" 
            data-rule-emailextra data-msg-emailextra="Bitte geben Sie eine gültige E-Mailadresse ein."
            />
          </div>
        </div>
    </yield>
  </paedml-modal>
    
    <script>
      import { handleSubmitCreateOrEdit } from '../util/api-util'
      import API from '../api'
      import * as R from 'ramda'
      
      this.opname = this.opts.opname
      this.data = {}
      this.api = API.moodle[this.opts.variant === 'students' ? 'student' : 'teacher']
      this.edit = true
      this.emailCol = this.opts.emailcol

      this.on('rowMappingRequested', (cols) => {
        this.data.email = cols[this.emailCol]
      })

      this.submitHandler = formData => {
        return handleSubmitCreateOrEdit(this, data => `Email "${formData.email}"`, data => `Email "${formData.email}"`)(formData)
      }
    </script>
</paedml-modal-edit-email>
