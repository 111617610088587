<paedml-modal-verify-externalid>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname}
                selectedrowsfunc={opts.selectedrowsfunc} idcol="{opts.idcol || 0}" idtextcol="{opts.idtextcol || 1}"
                submitfunc={submitHandler} patient={patient}
                cancelbutton={true} ref="modal"
                >

    <yield to="fields">
      <div class="row justify-content-center">
        <div class="col-5 ">
          <span>{parent.data.patient} nicht verifiziert</span>
        </div>
        <div class="col-1">
          <paedml-form-input name="isVerified"
            type="checkbox" revlut="jaNein" handlechange={parent.handleToggle}
          />
        </div>
        <div class="col-5">
          <span>{parent.data.patient} verifiziert</span>
        </div>
      </div>
  
      <virtual if={parent.data.selectedRows > 1}>
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-warning">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Der aktuelle Zustand kann nicht ermittelt werden, da mehrere {parent.patient.plural} ausgewählt sind.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import API from '../api'
    
    this.prefix = opts.prefix
    this.variant = opts.variant
    this.data = {
      text: "Externe IDs werden verifiziert",
      isVerified: false,
      selectedRows: 0
    }
    this.api = this.opts.api
    this.patient = opts.variant === "student" ? {singular: 'Schüler*in', plural: 'Schüler*innen'} : {singular: 'Lehrer*in', plural: 'Lehrer*innen'}
    this.op = opts.variant 
    
    this.handleToggle = (val) => {
      this.data.text = (val ? "verifiziert" : "nicht verifiziert")
      this.data.isVerified = val
      this.refs.modal.trigger('updateButton', this.data.isVerified ? 'Externe ID verifizieren' : 'Externe ID nicht verifizieren')
    }

    this.on('dataRequested', (selectedRows) => {
      this.data = {
        patient: selectedRows.length > 1 ? `${this.patient.plural} werden` : `${this.patient.singular} wird`,
        text: "verifiziert",
        selectedRows: selectedRows.length
      }
      this.setInitialState(opts.variant === 'student' ? selectedRows[0][15]: selectedRows[0][12])
    })

    this.setInitialState = (isVerifiedState) => {
      var isVerified = false;
      if(this.data.selectedRows === 1) {
        isVerified = isVerifiedState === "ja"
      } 
      this.handleToggle(isVerified)
    }

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Operation abgebrochen')

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const formatter = this.data.isVerified ? formatterLock : formatterUnlock
      const messageSuccess = formatter(idLut, true)
      const messageFailure = formatter(idLut, false)

      return API[this.op].externalId(formData.ids, this.data.isVerified).then(rsp => {
        const [ids, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server')

        const {succeeded, failed} = ids
        if (failed.length) {
          if (succeeded.length) {
            // partly success
            const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
            // remove success entries
            $('span.tag', this.refs.tip).each(function(i,e) { 
              if (sLut[$(e).text()]) $('span', e).click()
            })

            this.parent.trigger('reloadTable')
            return Promise.reject([messageSuccess(succeeded), messageFailure(failed)])
          }
           this.parent.trigger('reloadTable')
           return Promise.reject(messageFailure(failed))
        }

        this.parent.trigger('reloadTable')
        return messageSuccess(succeeded)
      })
    }

    const formatterLock = patientFormatter({singular: this.patient.singular, plural: this.patient.plural, for: 'für'}, 'verifiziert')
    const formatterUnlock = patientFormatter({singular: this.patient.singular, plural: this.patient.plural, for: 'für'}, 'nicht verifiziert')

  </script>
</paedml-modal-verify-externalid>
