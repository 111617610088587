export const importverwaltung = {
  headerTitle: 'Import',
  route: 'imports',
  pageTitle: 'paedML - Import',
  related: [
    { route: 'jobverwaltung/jobs', menuTitle: 'Jobs ansehen' },
    { route: 'benutzerverwaltung/lehrerinnen', menuTitle: 'Existierende / importierte Lehrer*innen anschauen' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Existierende / importierte Schüler*innen anschauen' },
    { route: 'benutzerverwaltung/schularten', menuTitle: 'Schularten anlegen' },
  ],
  cards: [
    { 
      route: 'teachers',
      icon: 'fas-file-csv',
      endpoint: '/api/import/teachers',
      endpoints: {
        post: '/api/import',
        delete: '/api/import',
      },
      preHooks: {
        list: 'imports_list_preHook'
      },
      tag: 'paedml-card-import',
      category: 'Import',
      menuTitle: 'Lehrer*innen',
      pageTitle: 'paedML - Lehrer*innen importieren',
      name: 'importlehrer',
      contentTitle: 'Lehrer*innen',
      prefix: 'importlehrer',
      suffix: undefined,
      custom: {
        usergroup: 1,
        variant: 'teachers'
      },
      table: {
        columns: [
          { title: '', name: 'icon', col: 0, priority: 1, searchable: false, printable: false, exportable: false, orderable: false, fnCreatedCell: (td, val) => val ? $(td).html(`<svg class="paedml-icon black" style="margin-top: -3px"><use xlink:href="#${val}" /></svg>`) : '' },
          { title: 'ID', name: 'id', col: 1, priority: 5 },
          { title: 'schoolTypeId', name: 'schoolTypeId', col: 2, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Schulart', name: 'schoolType', col: 3, filter: 'Schulart', priority: 10, filterType: 'regex' },
          { title: 'Benutzername', name: 'userName', col: 4, priority: 10 },
          { title: 'Nachname', name: 'surname', col: 5, priority: 10 },
          { title: 'Vorname', name: 'givenName', col: 6, priority: 9 },
          { title: 'Kürzel', name: 'initials', col: 7, priority: 4 },
          { title: 'Kennwortänderung erzwingen', name: 'changePasswordOnNextLogin', col: 8, priority: 3 },
          { title: 'Kennwort', name: 'password', col: 9, priority: 2 },
          { title: 'E-Mail', name: 'email', col: 10, priority: 1 },
          { title: 'Externe ID', name: 'externalId', col: 11, priority: 10 },
          { title: 'Externe ID verifiziert', name: 'verifyExternalId', col: 12, priority: 4 },
          { title: 'Moodlezugriff', name: 'activateMoodle', col: 13, priority: 2 },
          { title: 'Moodle E-Mail', name: 'moodleEmail', col: 14, priority: 1 },
          { title: 'Vermerk', name: 'vermerk', col: 15, priority: 10, filter: 'Vermerk' },
          { title: 'delId', name: 'delId', col: 16, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'errorType', name: 'errorType', col: 17, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/importlehrer.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 1, 'asc' ]],
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'students',
      icon: 'fas-file-csv',
      endpoint: '/api/import/students',
      endpoints: {
        post: '/api/import',
        delete: '/api/import',
      },
      preHooks: {
        list: 'imports_list_preHook'
      },
      tag: 'paedml-card-import',
      category: 'Import',
      menuTitle: 'Schüler*innen',
      pageTitle: 'paedML - Schüler*innen importieren',
      name: 'importschueler',
      contentTitle: 'Schüler*innen',
      prefix: 'importschueler',
      suffix: undefined,
      custom: {
        usergroup: 0,
        variant: 'students'
      },
      table: {
        columns: [
          { title: '', name: 'icon', col: 0, priority: 1, searchable: false, printable: false, exportable: false, orderable: false, fnCreatedCell: (td, val) => val ? $(td).html(`<svg class="paedml-icon black" style="margin-top: -3px"><use xlink:href="#${val}" /></svg>`) : '' },
          { title: 'ID', name: 'id', col: 1, priority: 5 },
          { title: 'schoolTypeId', name: 'schoolTypeId', col: 2, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Schulart', name: 'schoolType', col: 3, filter: 'Schulart', priority: 10, filterType: 'regex' },
          { title: 'Benutzername', name: 'userName', col: 4, priority: 10 },
          { title: 'Nachname', name: 'surname', col: 5, priority: 10 },
          { title: 'Vorname', name: 'givenName', col: 6, priority: 9 },
          { title: 'Klasse', name: 'schoolClass', col: 7, priority: 4 },
          { title: 'Kennwortänderung erzwingen', name: 'changePasswordOnNextLogin', col: 8, priority: 3 },
          { title: 'Kennwort', name: 'password', col: 9, priority: 2 },
          { title: 'E-Mail', name: 'email', col: 10, priority: 1 },
          { title: 'Externe ID', name: 'externalId', col: 11, priority: 10 },
          { title: 'Externe ID verifiziert', name: 'verifyExternalId', col: 12, priority: 4 },
          { title: 'Moodlezugriff', name: 'activateMoodle', col: 13, priority: 2 },
          { title: 'Moodle E-Mail', name: 'moodleEmail', col: 14, priority: 1 },
          { title: 'Vermerk', name: 'vermerk', col: 15, priority: 14, filter: 'Vermerk' },
          { title: 'delId', name: 'delId', col: 16, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'errorType', name: 'errorType', col: 17, visible: false, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/importschueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          order: [[ 1, 'asc' ]],
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

  ]
}