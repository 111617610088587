<paedml-qrcode>
    <script>
        this.on('update', () => {
            if (this.opts.content) {
                const content = this.opts.content()
                if (content) {
                    this.root.innerHTML = this.createQRCode(content)
                }
            }
        })

        this.createQRCode = (content) => {
            const svg = new QRCode({
            content: content,
            padding: 4,
            width: this.opts.width || 256,
            height: this.opts.height || 256,
            color: "#000000",
            background: "#ffffff",
            ecl: "M"
            }).svg()
            return svg
        }
    </script>
</paedml-qrcode>
