import '../paedml-modal.tag'
import '../paedml-form-input.tag'

<paedml-modal-cleanup-tags>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">
      <h5>Diese Aktion <span style="color:#dc3545; font-weight:bold;">l&ouml;scht </span> 
        die ungenutzten Tags <span style="color:#dc3545; font-weight:bold;">unwiderruflich</span>.</h5>
      
      <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">In diesem Fall bedeutet es: Links können mit diesen Tags nicht mehr gekoppelt werden.</strong>
          </span>
        </div>
        <div class="p-1"></div>
    </yield>
  </paedml-modal>
    
  <script>
    import API from '../../api'

    this.opname = this.opts.opname
    this.edit = this.opts.edit ? true : false
    this.data = {}
    this.api = this.opts.api
     
    this.submitHandler = formData => {
      return API.tags.unused().then(rsp => {
       
        this.parent.parent.opts.siblings.forEach(card => {
          card.tags['paedml-card'].trigger('reloadTable')
        })

        return 'Die Bereinigung der ungenutzten Tags wurde erfolgreich eingeleitet'

      })
    }

    </script>
</paedml-modal-cleanup-tags>
