export const bookmarks = {
    headerTitle: 'Linkverwaltung',
    route: 'bookmarks',
    pageTitle: 'paedML - Linkverwaltung',
    related: [
      { route: 'benutzerverwaltung', menuTitle: 'Benutzerverwaltung' }
    ],
    cards: [
      {
        route: 'bookmarks',
        icon: 'fas-link',
        endpoint: '/api/bookmarks',
        endpoints: {
        },
        preHooks: {
          list: 'bookmarks_list_preHook'
        },
        tag: 'paedml-card-bookmarks',
        category: 'Linkverwaltung',
        menuTitle: 'Links verwalten',
        pageTitle: 'paedML - Linkverwaltung: Links',
        name: 'bookmarks',
        contentTitle: 'Links',
        prefix: 'bookmarks',
        suffix: undefined,
        custom: {
          variant: 'bookmarks'
        },
        table: {
          columns: [
            { title: 'ID', name: "id", col: 0, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Titel', name: "nameDP", col: 1, searchable: false, printable: false, exportable: false, orderData: [3], fnCreatedCell: (td, sData, oData, iRow, iCol) => $(td).html(`<a href="${oData[4]}" target="_blank"><svg class="paedml-icon black"><use xlink:href="#far-share-square" /></svg> ${oData[2]}</a> `) },
            { title: 'Titel', name: "name", col: 2, visible: false },
            { title: 'Url', name: "url", col: 3, visible: false, searchable: false },
            { title: 'Url', name: "urlDP", col: 4, visible: false, searchable: false, exportable: false},
            { title: 'Beschreibung', name: "description", col: 5 },
            { title: 'Tags', name: "tags", col: 6, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Tags', name: "tagsDP", col: 7, separator: ', ',  filter: 'Tag', filterType: 'regex' },
            { title: 'Schulart(en)', name: "schoolTypes", col: 8, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Schulart(en)', name: "schoolTypesDP", separator: ', ', col: 9, filter: 'Schulart', filterType: 'regex' },
          ],
          data: {
            url: '',
            dataSrc: ''
          },
          custom: {
            pageLength: 10
          }
        }
      },
      {
        route: 'mybookmarks',
        icon: 'fas-link',
        endpoint: '/api/bookmarks/my',
        endpoints: {
        },
        preHooks: {
          list: 'bookmarks_list_preHook'
        },
        custom: {
          variant: 'mybookmarks'
        },
        tag: 'paedml-card-bookmarks',
        category: 'Linkverwaltung',
        menuTitle: 'Meine Links verwalten',
        pageTitle: 'paedML - Linkverwaltung: Meine Links',
        name: 'mybookmarks',
        contentTitle: 'Meine Links',
        prefix: 'mybookmarks',
        suffix: undefined,
        table: {
          columns: [
            { title: 'ID', name: "id", col: 0, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Titel', name: "nameDP", col: 1, searchable: false, printable: false, exportable: false, orderData: [3], fnCreatedCell: (td, sData, oData, iRow, iCol) => $(td).html(`<a href="${oData[4]}" target="_blank"><svg class="paedml-icon black"><use xlink:href="#far-share-square" /></svg> ${oData[2]}</a> `) },
            { title: 'Titel', name: "name", col: 2, visible: false },
            { title: 'Url', name: "url", col: 3, visible: false, searchable: false  },
            { title: 'Url', name: "urlDP", col: 4, visible: false, searchable: false, exportable: false },
            { title: 'Beschreibung', name: "description", col: 5 },
            { title: 'Tags', name: "tags", col: 6, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Tags', name: "tagsDP", col: 7, separator: ', ',  filter: 'Tag', filterType: 'regex' },
          ],
          data: {
            url: '',
            dataSrc: ''
          },
          custom: {
            pageLength: 10
          }
        }
      },
      {
        route: 'tags',
        icon: 'fas-tags',
        endpoint: '/api/tags',
        endpoints: {
        },
        preHooks: {
          list: 'tags_list_preHook'
        },
        tag: 'paedml-card-tags',
        category: 'Linkverwaltung',
        menuTitle: 'Tags verwalten',
        pageTitle: 'paedML - Linkverwaltung: Tags',
        name: 'tags',
        contentTitle: 'Tags',
        prefix: 'tags',
        suffix: undefined,
        table: {
          columns: [
            { title: 'ID', name: "id", col: 0, visible: false, searchable: false, printable: false, exportable: false },
            { title: 'Name', name: "tag", col: 1 },
          ],
          data: {
            url: '',
            dataSrc: ''
          },
          custom: {
            pageLength: 10
          }
        }
      }
  
    ]
  }
