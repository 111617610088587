import store from '../store'
import { toTrueMap } from '../util/mapping-util'

import * as R from 'ramda'
import Session from '../store/session'

const onlyIfOctoGateIsCurrentFW = () => Session.settings[0].firewallType === "OCTO"
const onlyIfOtherIsNotCurrentFW = () => Session.settings[0].firewallType !== "OTHER"

const onlyIfCloudEnabled = () => Session.feSettings.extensions.enableM365 === true
const onlyIfMoodleEnabled = () => Session.feSettings.extensions.enableMoodle === true
const onlyIfLapsEnabled = () => Session.feSettings.extensions.enableLaps === true

const acl = {

    "dashboard": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "dashboard/home": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "dashboard/all": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "dashboard/all.fav": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "dashboard/all.delete": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],

    "rechteverwaltung": [1, 5, 26],
    "rechteverwaltung/dienstekonten": [1],
    "rechteverwaltung/dienstekonten.password": [1],
    "rechteverwaltung/system-administratoren": [1],
    "rechteverwaltung/system-administratoren.create": [1],
    "rechteverwaltung/system-administratoren.edit": [1],
    "rechteverwaltung/system-administratoren.delete": [1],
    "rechteverwaltung/system-administratoren.password": [1],
    "rechteverwaltung/schulart-administratoren": [1],
    "rechteverwaltung/schulart-administratoren.create": [1],
    "rechteverwaltung/schulart-administratoren.edit": [1],
    "rechteverwaltung/schulart-administratoren.delete": [1],
    "rechteverwaltung/schulart-administratoren.password": [1],
    "rechteverwaltung/firewall-administratoren": [1],
    "rechteverwaltung/firewall-administratoren.create": [1],
    "rechteverwaltung/firewall-administratoren.edit": [1],
    "rechteverwaltung/firewall-administratoren.delete": [1],
    "rechteverwaltung/firewall-administratoren.password": [1],
    "rechteverwaltung/rollen-administratoren": [1, 5],
    "rechteverwaltung/rollen-administratoren.create": [1, 5],
    "rechteverwaltung/rollen-administratoren.edit": [1, 5],
    "rechteverwaltung/rollen-administratoren.delete": [1, 5],
    "rechteverwaltung/rollen-administratoren.password": [1, 5],
    "rechteverwaltung/computer-administratoren": [1, 5, 26, onlyIfLapsEnabled],
    "rechteverwaltung/computer-administratoren.password": [1, 5, 26, onlyIfLapsEnabled],

    "benutzerverwaltung": [1, 5, 6, 7, 8],
    "benutzerverwaltung/schularten": [1, 5],
    "benutzerverwaltung/schularten.create": [1],
    "benutzerverwaltung/schularten.fwaccess": [1, 5, onlyIfOctoGateIsCurrentFW],
    "benutzerverwaltung/schularten.edit": [1, 5],
    "benutzerverwaltung/schularten.run": [1, 5],
    "benutzerverwaltung/schularten.delete": [1],
    "benutzerverwaltung/klassen": [1, 5, 8],
    "benutzerverwaltung/klassen.create": [1, 5, 8],
    "benutzerverwaltung/klassen.info": [1, 5, 8],
    "benutzerverwaltung/klassen.edit": [1, 5, 8],
    "benutzerverwaltung/klassen.delete": [1, 5, 8],
    "benutzerverwaltung/klassen.cleanup": [1, 5, 8],
    "benutzerverwaltung/lehrerinnen": [1, 5, 7, 23],
    "benutzerverwaltung/lehrerinnen.create": [1, 5, 7],
    "benutzerverwaltung/lehrerinnen.info": [1, 5, 7, 23],
    "benutzerverwaltung/lehrerinnen.edit": [1, 5, 7, 23],
    "benutzerverwaltung/lehrerinnen.delete": [1, 5, 7],
    "benutzerverwaltung/lehrerinnen.password": [1, 5, 7],
    "benutzerverwaltung/lehrerinnen.sync": [1],
    "benutzerverwaltung/lehrerinnen.fix": [1, 5, 7],
    "benutzerverwaltung/lehrerinnen.activate": [1, 5, 7],
    "benutzerverwaltung/lehrerinnen.cleanup": [1, 5, 7],
    "benutzerverwaltung/lehrerinnen.externalId": [1, 5, 7, 23],
    "benutzerverwaltung/schuelerinnen": [1, 5, 6, 23],
    "benutzerverwaltung/schuelerinnen.create": [1, 5, 6],
    "benutzerverwaltung/schuelerinnen.info": [1, 5, 6, 23],
    "benutzerverwaltung/schuelerinnen.internetLock": [1, 5, 6, onlyIfOtherIsNotCurrentFW],
    "benutzerverwaltung/schuelerinnen.edit": [1, 5, 6, 23],
    "benutzerverwaltung/schuelerinnen.delete": [1, 5, 6],
    "benutzerverwaltung/schuelerinnen.password": [1, 5, 6],
    "benutzerverwaltung/schuelerinnen.sync": [1],
    "benutzerverwaltung/schuelerinnen.fix": [1, 5, 6],
    "benutzerverwaltung/schuelerinnen.activate": [1, 5, 6],
    "benutzerverwaltung/schuelerinnen.cleanup": [1, 5, 6],
    "benutzerverwaltung/schuelerinnen.externalId": [1, 5, 6, 23],

    "jobverwaltung": [1, 4, 5, 6, 7, 8, 12, 14, 15],
    "jobverwaltung/jobs": [1, 4, 5, 6, 7, 8, 12, 14, 15],
    "jobverwaltung/jobs.info": [1, 4, 5, 6, 7, 8, 12, 14, 15],
    "jobverwaltung/jobs.run": [1, 5, 12],
    "jobverwaltung/jobs.delete": [1, 5, 12],
    "jobverwaltung/tasks": [1, 4, 5, 6, 7, 8, 12, 14, 15],
    "jobverwaltung/tasks.run": [1, 5, 12],
    "jobverwaltung/tasks.delete": [1, 5, 12],

    // 9: raum-adm, 10: comp-adm, 11: drucker-adm
    "raumverwaltung": [1, 4, 5, 9, 10, 11],
    "raumverwaltung/raum": [1, 4, 5, 9],
    "raumverwaltung/raum.create": [1, 5, 9],
    "raumverwaltung/raum.sync": [1, 5, 9],
    "raumverwaltung/raum.info": [1, 4, 5, 9],
    "raumverwaltung/raum.edit": [1],
    "raumverwaltung/raum.bsa": [1, 4, 5, 9],
    "raumverwaltung/raum.run": [1, 5, 9],
    "raumverwaltung/raum.delete": [1],
    "raumverwaltung/computer": [1, 4, 5, 10],
    "raumverwaltung/computer.sync": [1, 5, 10],
    "raumverwaltung/computer.info": [1, 4, 5, 10],
    "raumverwaltung/computer.move": [1, 5, 10],
    "raumverwaltung/computer.run": [1, 4, 5, 10],
    "raumverwaltung/computer.lock": [1, 4, 5, 10],
    "raumverwaltung/computer.internetLock": [1, 4, 5, 10, onlyIfOtherIsNotCurrentFW],
    "raumverwaltung/computer.logoff": [1, 4, 5, 10],
    "raumverwaltung/computer.start": [1, 4, 5, 10],
    "raumverwaltung/computer.shutdown": [1, 4, 5, 10],
    "raumverwaltung/computer.reboot": [1, 4, 5, 10],
    "raumverwaltung/computer.screenshot": [1, 4, 5, 10],
    "raumverwaltung/computer.edit": [1, 5, 10],
    "raumverwaltung/computer.password": [1],
    "raumverwaltung/drucker": [1, 4, 5, 11],
    "raumverwaltung/drucker.sync": [1, 5, 11],
    "raumverwaltung/drucker.info": [1, 4, 5, 11],
    "raumverwaltung/drucker.move": [1, 5, 11],
    "raumverwaltung/drucker.edit": [1, 4, 5, 11],
    "raumverwaltung/drucker.run": [1, 5, 11],
    "raumverwaltung/bsa": [1, 4, 5],
    "raumverwaltung/bsa.edit": [1, 4, 5],
    "raumverwaltung/bsa.approve": [1, 4, 5],
    "raumverwaltung/bsa.delete": [1, 4, 5],

    "imports": [1, 5, 15],
    "imports/teachers": [1, 5, 15],
    "imports/teachers.info": [1, 5, 15],
    "imports/teachers.create": [1, 5, 15],
    "imports/teachers.edit": [1, 5, 15],
    "imports/teachers.run": [1, 5, 15],
    "imports/teachers.delete": [1, 5, 15],
    "imports/students": [1, 5, 15],
    "imports/students.info": [1, 5, 15],
    "imports/students.create": [1, 5, 15],
    "imports/students.edit": [1, 5, 15],
    "imports/students.run": [1, 5, 15],
    "imports/students.delete": [1, 5, 15],

    "versetzung": [1, 5, 14],
    "versetzung/aufbereitung": [1, 5, 14],
    "versetzung/aufbereitung.info": [1, 5, 14],
    "versetzung/aufbereitung.create": [1, 5, 14],
    "versetzung/klassen": [1, 5, 14],
    "versetzung/klassen.info": [1, 5, 14],
    "versetzung/klassen.create": [1, 5, 14],
    "versetzung/freigabe": [1, 5, 14], // anpassen und freigeben
    "versetzung/freigabe.info": [1, 5, 14],
    "versetzung/freigabe.create": [1, 5, 14],
    "versetzung/freigabe.reset": [1, 5, 14],
    "versetzung/freigabe.run": [1, 5, 14],
    "versetzung/verarbeitung": [1, 5, 14], // anpassen und freigeben
    "versetzung/verarbeitung.info": [1, 5, 14],
    "versetzung/verarbeitung.create": [1, 5, 14],
    "versetzung/verarbeitung.edit": [1, 5, 14],
    "versetzung/verarbeitung.run": [1, 5, 14],
    "versetzung/verarbeitung.delete": [1, 5, 14],

    "meine": [4],
    "meine/klassen": [4],
    "meine/klassen.fav": [4],
    "meine/klassen.info": [4],
    "meine/klassen.fwaccess": [4, onlyIfOctoGateIsCurrentFW],
    "meine/klassen.handout": [4],
    "meine/schuelerinnen": [4],
    "meine/schuelerinnen.info": [4],
    "meine/schuelerinnen.password": [4],
    "meine/schuelerinnen.handout": [4],
    "meine/schuelerinnen.addtoproj": [4],
    "meine/alle-schuelerinnen": [4],
    "meine/alle-schuelerinnen.info": [4],
    "meine/alle-schuelerinnen.password": [4],
    "meine/alle-schuelerinnen.handout": [4],
    "meine/alle-schuelerinnen.addtoproj": [4],
    "meine/ausgeteilte-dateien": [4],
    "meine/ausgeteilte-dateien.collect": [4],
    "meine/ausgeteilte-dateien.info": [4],
    "meine/ausgeteilte-dateien.delete": [4],
    "meine/projekte": [4],
    "meine/projekte.create": [4],
    "meine/projekte.info": [4],
    "meine/projekte.edit": [4],
    "meine/projekte.delete": [4],
    "meine/projekte.handout": [4],
    "meine/projekte.cleanup": [4],

    "klassenarbeiten": [4],
    "klassenarbeiten/klassenarbeiten": [4],
    "klassenarbeiten/klassenarbeiten.create": [4],
    "klassenarbeiten/klassenarbeiten.info": [4],
    "klassenarbeiten/klassenarbeiten.handout": [4],
    "klassenarbeiten/klassenarbeiten.collect": [4],
    "klassenarbeiten/klassenarbeiten.fwaccess": [4, onlyIfOctoGateIsCurrentFW],
    "klassenarbeiten/klassenarbeiten.run": [4],
    "klassenarbeiten/klassenarbeiten.edit": [4],
    "klassenarbeiten/klassenarbeiten.delete": [4],
    "klassenarbeiten/protokolle": [4],

    // 16: global-fw-adm
    "firewallverwaltung": [1, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/settings": [1, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/settings.edit": [1, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/templates": [1, 5, 13, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/templates.edit": [1, 5, 13, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/klassen": [1, 5, 13, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/klassen.edit": [1, 5, 13, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/global": [1, 16, onlyIfOctoGateIsCurrentFW],
    "firewallverwaltung/global.edit": [1, 16, onlyIfOctoGateIsCurrentFW],

    // Quota: 5: sadm, 6: sus-adm, 7: lul-adm
    "quotaverwaltung": [1, 5, 6, 7],

    "quotaverwaltung/schuelerinnen": [1, 5, 6],
    "quotaverwaltung/schuelerinnen.edit": [1, 5, 6],
    "quotaverwaltung/lehrerinnen": [1, 5, 7],
    "quotaverwaltung/lehrerinnen.edit": [1, 5, 7],

    // Wartung
    "wartung": [1],
    "wartung/ausgeteilte-dateien": [1],
    "wartung/ausgeteilte-dateien.collect": [1],
    "wartung/ausgeteilte-dateien.info": [1],
    "wartung/ausgeteilte-dateien.delete": [1],
    "wartung/projekte": [1],
    "wartung/projekte.info": [1],
    "wartung/projekte.fwaccess": [1, onlyIfOctoGateIsCurrentFW],
    "wartung/projekte.edit": [1],
    "wartung/projekte.delete": [1],
    "wartung/projekte.cleanup": [1],
    "wartung/klassenarbeiten": [1, 5],
    "wartung/klassenarbeiten.info": [1, 5],
    "wartung/klassenarbeiten.collect": [1, 5],
    "wartung/klassenarbeiten.fwaccess": [1, 5, onlyIfOctoGateIsCurrentFW],
    "wartung/klassenarbeiten.run": [1, 5],
    "wartung/klassenarbeiten.edit": [1, 5],
    "wartung/klassenarbeiten.delete": [1, 5],
    "wartung/protokolle": [1, 5],

    // cloud: 20 M365-adm
    "cloud": [1, 20, onlyIfCloudEnabled],
    "cloud/domains": [1, onlyIfCloudEnabled],
    "cloud/domains.create": [1, onlyIfCloudEnabled],
    "cloud/domains.delete": [1, onlyIfCloudEnabled],
    "cloud/lehrerinnen": [1, 20, onlyIfCloudEnabled],
    "cloud/lehrerinnen.edit": [1, 20, onlyIfCloudEnabled],
    "cloud/lehrerinnen.access": [1, 20, onlyIfCloudEnabled],
    "cloud/schuelerinnen": [1, 20, onlyIfCloudEnabled],
    "cloud/schuelerinnen.edit": [1, 20, onlyIfCloudEnabled],
    "cloud/schuelerinnen.access": [1, 20, onlyIfCloudEnabled],

    // moodle: 21 Moodle-adm
    "moodle": [1, 21, onlyIfMoodleEnabled],
    "moodle/domains": [1, onlyIfMoodleEnabled],
    "moodle/domains.create": [1, onlyIfMoodleEnabled],
    "moodle/domains.delete": [1, onlyIfMoodleEnabled],
    "moodle/lehrerinnen": [1, 21, onlyIfMoodleEnabled],
    "moodle/lehrerinnen.edit": [1, 21, onlyIfMoodleEnabled],
    "moodle/lehrerinnen.access": [1, 21, onlyIfMoodleEnabled],
    "moodle/schuelerinnen": [1, 21, onlyIfMoodleEnabled],
    "moodle/schuelerinnen.edit": [1, 21, onlyIfMoodleEnabled],
    "moodle/schuelerinnen.access": [1, 21, onlyIfMoodleEnabled],

    "profilverwaltung": [1, 5, 19, 22],
    "profilverwaltung/lehrerinnen": [1, 5, 19, 22],
    "profilverwaltung/lehrerinnen.create": [1, 22],
    "profilverwaltung/lehrerinnen.edit": [1, 22],
    "profilverwaltung/lehrerinnen.duplicate": [1, 22],
    "profilverwaltung/lehrerinnen.assign": [1, 22],
    "profilverwaltung/lehrerinnen.activate": [1, 5, 19, 22],
    "profilverwaltung/lehrerinnen.reset": [1],
    "profilverwaltung/lehrerinnen.delete": [1, 22],
    "profilverwaltung/schuelerinnen": [1, 5, 19, 22],
    "profilverwaltung/schuelerinnen.create": [1, 22],
    "profilverwaltung/schuelerinnen.edit": [1, 22],
    "profilverwaltung/schuelerinnen.duplicate": [1, 22],
    "profilverwaltung/schuelerinnen.assign": [1, 22],
    "profilverwaltung/schuelerinnen.activate": [1, 5, 19, 22],
    "profilverwaltung/schuelerinnen.reset": [1],
    "profilverwaltung/schuelerinnen.delete": [1, 22],
    "profilverwaltung/klassenarbeiten": [1, 5, 19, 22],
    "profilverwaltung/klassenarbeiten.create": [1, 22],
    "profilverwaltung/klassenarbeiten.edit": [1, 22],
    "profilverwaltung/klassenarbeiten.duplicate": [1, 22],
    "profilverwaltung/klassenarbeiten.assign": [1, 22],
    "profilverwaltung/klassenarbeiten.activate": [1, 5, 19, 22],
    "profilverwaltung/klassenarbeiten.reset": [1],
    "profilverwaltung/klassenarbeiten.delete": [1, 22],

    "singlelogin": [1, 4, 5, 6, 7],
    "singlelogin/lehrerinnen": [1, 5, 7],
    "singlelogin/lehrerinnen.edit": [1, 5, 7],
    "singlelogin/lehrerinnen.freigabe": [1, 5, 7],
    "singlelogin/schuelerinnen": [1, 4, 5, 6],
    "singlelogin/schuelerinnen.edit": [1, 5, 6],
    "singlelogin/schuelerinnen.freigabe": [1, 4, 5, 6],
    "singlelogin/klassenarbeiten": [1, 4, 5, 6],
    "singlelogin/klassenarbeiten.edit": [1, 5, 6],
    "singlelogin/klassenarbeiten.freigabe": [1, 4, 5, 6],

    "software": [1, 5, 24, 25],
    "software/software": [1, 5, 24, 25],
    "software/software.rescan": [1, 5, 25],
    "software/software.info": [1, 5, 24, 25],
    "software/software.assign": [1, 5, 24, 25],
    "software/software.edit": [1, 5, 25],
    "software/softwarecomputer": [1, 5, 24, 25],
    "software/softwarecomputer.sync": [1, 5, 24, 25],
    "software/softwarecomputer.rescan": [1, 5, 25],
    "software/softwarecomputer.assign": [1, 5, 24, 25],
    "software/softwarecomputer.ondemand": [1, 5, 24, 25],
    "software/softwarecomputer.info": [1, 5, 24, 25],

    "bookmarks": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "bookmarks/bookmarks": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "bookmarks/bookmarks.create": [1, 5, 27],
    "bookmarks/bookmarks.edit": [1, 5, 27],
    "bookmarks/bookmarks.delete": [1, 5, 27],
    "bookmarks/bookmarks.info": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "bookmarks/bookmarks.tags": [1, 5, 27],
    "bookmarks/mybookmarks": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "bookmarks/mybookmarks.create": [1, 4, 27],
    "bookmarks/mybookmarks.edit": [1, 4, 27],
    "bookmarks/mybookmarks.delete": [1, 4, 27],
    "bookmarks/mybookmarks.info": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "bookmarks/tags": [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 19, 20, 21, 22, 23, 24, 25, 26, 27],
    "bookmarks/tags.delete": [1, 27],
    "bookmarks/tags.unused": [1, 27],
}

const [aclLut, condLut] = Object.keys(acl)
    .reduce((acc, k) => {
        const [roles, conditions] = R.partition(v => R.type(v) !== 'Function', acl[k])
        const listAsMap = toTrueMap(roles)
        const [a, c] = acc

        // expand mapping to allow path starting with '#' and '/' besides the original path
        a['#' + k] = listAsMap;
        a['/' + k] = listAsMap;
        a[k] = listAsMap;
        c['#' + k] = conditions;
        c['/' + k] = conditions;
        c[k] = conditions;
        return acc
    }, [{}, {}])

const isPermitted = (item) => {
    if (!item) return false
    const conditions = condLut[item] || []
    if (conditions.length && R.any(f => !f(), conditions)) return false

    const { user } = store.getState()
    if (!user) return false;

    if ((user.roles || []).length === 0) return false

    const perms = aclLut[item]
    if (!perms) return false

    const permitted = user.roles
        .map(role => role.id)
        .find(roleId => perms[roleId])

    return permitted
}

const isPermittedForRoles = (item, roles) => {
    if (!item) return false

    if ((roles || []).length === 0) return false

    const perms = aclLut[item]
    if (!perms) return false

    const permitted = roles
        .find(roleId => perms[roleId])

    return permitted
}

export { isPermitted, isPermittedForRoles }
