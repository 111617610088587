import createClient from '../api/client'
import {custom} from '../api/custom'
import * as R from 'ramda'

// default singleton client
export const client = createClient()

const normalizeCSV = (sep = ',', nsep = ', ') => 
    R.compose(R.join(nsep), R.split(sep))

const defaultSeparator = ', '

const _createGet = fieldDef => {
    const plan = fieldDef.reduce((acc,fd) => {
        const valFn = fd.fn ? e => fd.fn(e) : e => e[fd.name]
        const colFn = fd.separator && fd.separator !== defaultSeparator ? 
            e => normalizeCSV(fd.separator, defaultSeparator)(valFn(e)) : valFn
        acc.push(colFn)
        return acc
    }, [])
    return row => plan.map(fn => normalizeValue(fn(row)))
}

const normalizeValue = val => {
    if (val === null || val === undefined) return ''
    if (val === true || val === 'true' || val === 'True') return 'ja'
    if (val === false || val === 'false' || val === 'False') return 'nein'
    if (typeof val === 'string') return val.replace(/\</g,'&lt;').replace(/\>/g,'&gt;')
    return val
}

export const createDataTablesCallback = api => {
    return function (tdata, callback, settings) {
        api.list()
            .then(res => {
                const [data, status] = res
                if (status.success) {
                    return callback({data})
                } else {
                    console.error('error-status', status)
                    console.error('error-data', data)
                    return callback({data: []})
                }
            })
    }
}

const cache = {}
export const getAPIForCard = (card) => {
    const name = card.name
    if (name === undefined) return undefined

    let cached = cache[name]
    if (!cached) {
        cached = createAPIForCard(card)
        cache[name] = cached
    }

    return cached
}

const identity = rsp => rsp

const getListPreHook = ({preHooks}) => {
    if (preHooks) {
        const {list} = preHooks
        if (list) return custom[list](client)
    }
    return identity
}

export const createAPIForCard = (card) => {
    const columns = card.table.columns
    const endpoint = card.endpoint
    const endpoints = card.endpoints || {}
    const endpointGetAll = endpoints.getAll || endpoint
    const endpointGet = endpoints.get || endpoint
    const endpointPost = endpoints.post || endpoint
    const endpointPut = endpoints.put || endpoint
    const endpointDelete = endpoints.delete || endpoint
    const endpointPassword = endpoints.password
    
    const preHook = getListPreHook(card)

    const mappingGet = R.map(_createGet(columns))
    const getAll = () => client.get(endpointGetAll)

    const list = () => client.get(endpointGetAll)
        .then(rsp => preHook(rsp))
        .then((res) => {
            const [data, status, ctx] = res
            return status.success ? [mappingGet(data), status, ctx] : res
        })

    const get = (id) => client.get(`${endpointGet}/${id}`)

    const create = (payload) => client.post(endpointPost, payload)

    const update = R.curry((id, payload) => client.put(`${endpointPut}/${id}`, payload))

    const remove = (idOrIds) => {
        if (idOrIds instanceof Array) {
            return client.delete(endpointDelete, { data: idOrIds })
        }
        client.delete(`${endpointDelete}/${idOrIds}`)
    }

    const api = { 
        getAll,
        list,
        get,
        create,
        update,
        remove
    }

    if (endpointPassword) {
        api.password = payload => client.post(endpointPassword, payload)
    }

    return api
}

// taken from underscore
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

/**
 * Creates two functions [isAvailable and clearCtx].
 * isAvailable: (ctx, delay, callback) => void : after given delay (in millis) the supplied function will be executed
 * clearCtx: ctx => void : removes last input involved in background checks
 * 
 * @param {function(void):function(any):boolean} checkingApi (function supplier)
 * @param {string} inputName e.g. username, domain etc
 */
const createBackgroundChecker = (checkingApi, inputName) => {
  const clearCtx = ctx => {
    delete ctx.lastInput
    delete ctx.lastResult
  }

  const isAvailable = (ctx, delay, callback) => {
      const isAvailableInternal2 = param => checkingApi()(param).then(available => {
      ctx.isChecking = true
      $(`input[name=${inputName}]`, ctx.root).data(inputName, available)
      $(`input[name=${inputName}]`, ctx.root).valid()

      if (!available) {
        ctx.resolved = false
        ctx.data.isDuplicate = true
        ctx.data.surpressWarnings = false
      } else {
        ctx.data.isDuplicate = false
        ctx.resolved = true
      }
      if (callback) {
        callback(available)
      }
      ctx.isChecking = false

      ctx.update()
    })

    const isAvailableInternal = param => {
      if (ctx.lastInput === param) {
        ctx.isChecking = false
        ctx.resolved = true
        ctx.update()
        return ctx.lastResult
      }
      ctx.isChecking = true
      ctx.resolved = false
      ctx.update()
      
      return isAvailableInternal2(param).then(result => {
        ctx.lastInput = param
        ctx.lastResult = result
        return result
      })
    }

    return debounce(isAvailableInternal, delay)
  }

  return [isAvailable, clearCtx]
}

export const [userNameAvailable, clearUserNameAvailabilityCtx] = createBackgroundChecker(() => API.user.available, 'userName')
export const [projNameAvailable, clearProjNameAvailabilityCtx] = createBackgroundChecker(() => API.projekte.available, 'name')
export const [domainAvailable, clearDomainAvailabilityCtx] = createBackgroundChecker(() => API.domain.resolve, 'domain')

export const getSubmitError = (ctx, rsp, msg = 'Die Aktion konnte nicht durchgeführt werden.') => {
    const [data, status] = rsp
    console.error('error-status', status)
    console.error('error-data', data)
    let reason

    const checkForMessage = () => {
        const {message} = data
        if (status.code === 500 && (!message || message === 'Ein Fehler ist aufgetreten.')) {
            return 'Bitte probieren Sie es zu einem späteren Zeitpunkt noch einmal.'
        }
        if (status.code === 400 && title === 'One or more validation errors occurred.') {
            return 'Die Anfrage wurde nicht vom Server akzeptiert.'
        }
        if (status.code > 400 && status.code < 500 && message) {
            return `Grund: ${message}`
        }
        if (message) return message
        
        return 'Ein Kommunikationsfehler mit dem Server liegt vor.'
    }

    if (status.code === 400) {
      if (data.errors) {
        reason = 'Grund: ' + R.compose(R.join('<br/>'), R.flatten, R.values)(data.errors)
        const names = R.map(e =>  e.replace(/.+?[.](.)/, (s, a) => a.toLowerCase()))(R.keys(data.errors))
        const form = $('form', ctx.root)
        names.forEach(name => {
            $(`input[name="${name}"]`, form).removeClass('is-valid').addClass('is-invalid')
        })
      } else {
        reason = checkForMessage()
      }
    } else {
      reason = checkForMessage()
    }

    return reason ? `${msg}.<br/>${reason}` : msg
}

export const handleSubmitAction = (ctx, rsp, subject, actionInPastTense, relogin=false) => {
    const [data, status] = rsp
    if (status.success) {
        ctx.parent.trigger('reloadTable')
        if (relogin) window.request.relogin()
        return `${subject} erfolgreich ${actionInPastTense}.`
    }

    return Promise.reject(getSubmitError(ctx, rsp, `${subject} konnte nicht ${actionInPastTense} werden!`))
}

export const handleSubmitActionExecutor = (ctx, api, subject, actionInPastTense, relogin=false) => {
  return api().then(rsp => {
    return handleSubmitAction(ctx, rsp, subject, actionInPastTense, relogin)
  })
}

export const handleSubmitCreate = (ctx, rsp, subjectSuccess, subjectError, relogin=false) => {
    const [data, status] = rsp
    if (status.success) {
        ctx.parent.trigger('reloadTable')
        if (relogin) window.request.relogin()
        return `${subjectSuccess} erfolgreich angelegt.`
    }

    const se = subjectError ? subjectError : subjectSuccess
    return Promise.reject(getSubmitError(ctx, rsp, `${se} konnte nicht angelegt werden!`))
}

export const handleSubmitEdit = (ctx, rsp, msgSuccess = "Änderung(en) erfolgreich übernommen.", msgError = "Änderung(en) konnten nicht übernommen werden!", keepSelection = false) => {
    const [data, status] = rsp
    if (status.success) {
        ctx.parent.trigger('reloadTable', keepSelection)
        return msgSuccess
    }

    return Promise.reject(getSubmitError(ctx, rsp, msgError))
}

export const handleSubmitCreateOrEdit = (ctx, subjectSuccessFunc, subjectErrorFunc, relogin = false, keepSelection = false) => (formData) => {   
  if (ctx.edit) {
      return ctx.api.update(formData.ids[0], R.omit(['ids', 'idTexts'], formData))
        .then(rsp => {
          return handleSubmitEdit(ctx, rsp, undefined, undefined, keepSelection)
        })
    }

    return ctx.api.create(formData).then(rsp => {
      const [data, status] = rsp
      return handleSubmitCreate(ctx, rsp, subjectSuccessFunc(data, formData), subjectErrorFunc(formData), relogin)
    })
}

const extractFullUser = subject => data => {
    const {givenName, surname, userName} = data
    let display = givenName ? givenName : ''
    if (surname) {
        display = display ? `${display} ${surname}` : surname
    }
    if (userName) {
        display = display ? `${display} (${userName})` : userName
    }
    return `${subject} "${display}"`
}

export const handleSubmitCreateOrEditUser = (ctx, subject) => handleSubmitCreateOrEdit(ctx, extractFullUser(subject), extractFullUser(subject))

export const createTimerTask = (name, func) => () => {
  const delme = []
  R.forEachObjIndexed((v,k) => {
    if (k !== name && v) {
      clearTimeout(v)
      delme.push(k)
    }
  }, Session.timers)
  delme.forEach(k => {delete Session.timers[k]})
  if (Session.timers[name]) return true
  func()
  return true
}

export const createTimerCanceler = name => () => {
  clearTimeout(Session.timers[name])
  delete Session.timers[name]
}

export const createRefresher = (context, name, timeout) => (e) => {
  if (e && Session.timers[name]) {
    context.clearTimer()
  }

  if (!R.endsWith(name, window.location.href)) {
    if (Session.timers[name]) {
      context.clearTimer()
    }
    return
  }

  if (!context.refs.card.active) {
    context.clearTimer()
    return
  }

  context.refs['card'].trigger('reloadTable')
  if (!Session.timers[name]) {
    Session.timers[name] = setTimeout(() => {
        context.clearTimer()
        context.handleIntervalRefresh()
    }, timeout)

    context.update()
  }
}
