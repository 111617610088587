export const versetzung = {
  headerTitle: 'Versetzung / Verschiebung',
  route: 'versetzung',
  pageTitle: 'paedML - Versetzung',
  related: [
    { route: 'jobverwaltung/jobs', menuTitle: 'Jobs ansehen' },
    { route: 'benutzerverwaltung/schuelerinnen', menuTitle: 'Existierende / versetzte Schüler*innen anschauen' },
    { route: 'benutzerverwaltung/klassen', menuTitle: 'Klassen verwalten' },
  ],
  cards: [

    {
      route: 'klassen',
      icon: 'fas-arrows-alt',
      endpoint: '/api/relocation/schoolClass',
      tag: 'paedml-card-versetzung-klassen',
      category: 'Versetzung',
      menuTitle: 'Aufbereiten Klassen',
      pageTitle: 'paedML - Klassenversetzungen aufbereiten',
      name: 'versetzungklassen',
      contentTitle: 'Aufbereiten Klassen',
      prefix: 'versetzungklassen',
      suffix: undefined,
      custom: {
        mode: 'klassen'
      },
      table: {
        columns: [
          { title: 'ID', name: 'id', col: 0, visible: false, searchable: false, printable: false, exportable: false },
          { title: 'Klasse', name: 'schoolClass', col: 1, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex'},
          { title: 'Schulart', name: 'schoolType', col: 2, filter: 'Schulart', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 3 },
          { title: '# SuS', name: 'numberOfStudents', col: 4, searchable: false, printable: false, exportable: false },
        ],
        data: {
          url: '/data/klassen.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'aufbereitung',
      icon: 'fas-arrows-alt',
      endpoint: '/api/relocation',
      endpoints: {
      },
      preHooks: {
        list: 'versetzung_init_list_preHook'
      },
      tag: 'paedml-card-versetzung',
      category: 'Versetzung',
      menuTitle: 'Aufbereiten',
      pageTitle: 'paedML - Versetzungen aufbereiten',
      name: 'versetzungaufbereiten',
      contentTitle: 'Aufbereiten',
      prefix: 'versetzungaufbereiten',
      suffix: undefined,
      custom: {
        mode: 'aufbereitung'
      },
      table: {
        columns: [
          { title: '', col: 0, visible: false, searchable: false, printable: false, exportable: false, orderable: false},
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Schulart', name: 'schoolType', col: 4, filter: 'Schulart', filterType: 'regex' },
          { title: 'Klasse', name: 'schoolClass', col: 5, filter: 'Klasse', dynamicfilter: 'klassen', filterType: 'regex' },
          { title: 'Schuljahr', name: 'schoolYear', col: 6 },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'freigabe',
      icon: 'fas-check-double',
      endpoint: '/api/relocation',
      endpoints: {
      },
      preHooks: {
        list: 'versetzung_edit_list_preHook'
      },
      tag: 'paedml-card-versetzung',
      category: 'Versetzung',
      menuTitle: 'Anpassen / freigeben',
      pageTitle: 'paedML - Versetzungen Anpassen und freigeben',
      name: 'versetzungfreigabe',
      contentTitle: 'Anpassen und freigeben',
      prefix: 'versetzungfreigabe',
      suffix: undefined,
      custom: {
        mode: 'freigabe'
      },
      table: {
        columns: [
          { title: '', col: 0, visible: false, searchable: false, printable: false, exportable: false, orderable: false},
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Versetzung von', name: 'versetzungVon', col: 4 },
          { title: 'Versetzung nach', name: 'versetzungNach', col: 5 },
          { title: 'Schulart', name: 'schoolType', col: 6, visible: false },
          { title: 'Ziel-Schulart', name: 'targetSchoolType', col: 7, visible: false },
          { title: 'Klasse', name: 'schoolClass', col: 8, visible: false },
          { title: 'Ziel-Klasse', name: 'targetSchoolClass', col: 9, visible: false },
          { title: 'Schuljahr', name: 'schoolYear', col: 10, visible: false },
          { title: 'Ziel-Schuljahr', name: 'targetSchoolYear', col: 11, visible: false },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

    {
      route: 'verarbeitung',
      icon: 'fas-stream',
      endpoint: '/api/relocation',
      endpoints: {
      },
      preHooks: {
        list: 'versetzung_progress_list_preHook'
      },
      tag: 'paedml-card-versetzung',
      category: 'Versetzung',
      menuTitle: 'Verarbeitungsstatus',
      pageTitle: 'paedML - Versetzungen starten und überprüfen',
      name: 'versetzungverarbeitung',
      contentTitle: 'Verarbeitungsstatus',
      prefix: 'versetzungverarbeitung',
      suffix: undefined,
      custom: {
        mode: 'verarbeitung'
      },
      left: 5,
      table: {
        columns: [
          { title: '', name: 'icon', col: 0, priority: 10, searchable: false, printable: false, exportable: false, orderable: false, fnCreatedCell: (td, val) => val ? $(td).html(`<svg class="paedml-icon black" style="margin-top: -3px"><use xlink:href="#${val}" /></svg>`) : '' },
          { title: 'Benutzername', name: 'userName', col: 1 },
          { title: 'Nachname', name: 'surname', col: 2 },
          { title: 'Vorname', name: 'givenName', col: 3 },
          { title: 'Versetzung von', name: 'versetzungVon', col: 4 },
          { title: 'Versetzung nach', name: 'versetzungNach', col: 5 },
          { title: 'Schulart', name: 'schoolType', col: 6, visible: false },
          { title: 'Ziel-Schulart', name: 'targetSchoolType', col: 7, visible: false },
          { title: 'Klasse', name: 'schoolClass', col: 8, visible: false },
          { title: 'Ziel-Klasse', name: 'targetSchoolClass', col: 9, visible: false },
          { title: 'Schuljahr', name: 'schoolYear', col: 10, visible: false },
          { title: 'Ziel-Schuljahr', name: 'targetSchoolYear', col: 11, visible: false },
          { title: 'Status', name: 'status', col: 12, priority: 10, filter: 'Status' },
        ],
        data: {
          url: '/data/schueler.json',
          dataSrc: ''
        },
        custom: {
          order: [[ 1, 'asc' ]],
          pageLength: 10,
          select: {
            style: 'multi+shift'
          }
        }
      }
    },

  ]
}
