<paedml-modal-delete>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} opname="Zustimmen und ausführen"
                selectedrowsfunc={opts.selectedrowsfunc}
                idcol={opts.idcol} idtextcol={opts.idtextcol}
                submitfunc={submitHandler}
                submitbuttontype="danger"
                cancelbutton={true}
                patient={patient}
                relogin={opts.relogin}
                >

    <yield to="fields">
      <h5>Diese Aktion <span style="color:#dc3545; font-weight:bold;">l&ouml;scht </span> 
        {parent.getPatientText()} <span style="color:#dc3545; font-weight:bold;">unwiderruflich</span>.</h5>

      <virtual if="{patient.plural === 'Benutzerkonten'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-danger">
          <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
            <strong class="text-danger pl-1 pt-2">Durch das Löschen eines Benutzerkontos wird das Home-Laufwerk des Benutzers und damit alle dort gespeicherten Daten gelöscht.
              <br/><br/>
              Daten, die sich noch im Ordner "_ausgeteilt" eines Benutzers befinden, werden auf SP01 in D:\MLData\Wartung\Auto-Eingesammelt gesichert.<br/><br/>
              Sie können die Aktion nicht rückgängig machen.
            </strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <virtual if="{patient.plural === 'Klassen'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-danger">
          <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
            <strong class="text-danger pl-1 pt-2">Durch das Löschen einer Klasse werden alle Daten im Tauschlaufwerk der Klasse ebenfalls gelöscht.
              <br/><br/>
              Daten, die sich noch im Ordner "_ausgeteilt" eines Benutzers befinden, werden auf SP01 in D:\MLData\Wartung\Auto-Eingesammelt gesichert.<br/><br/>
              Sie können die Aktion nicht rückgängig machen.
            </strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <virtual if="{patient.plural === 'Projekte'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-danger">
          <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
            <strong class="text-danger pl-1 pt-2">Durch das Löschen eines Projekts werden alle Daten im Tauschlaufwerk des Projekts ebenfalls gelöscht.
              <br/><br/>
              Sie können die Aktion nicht rückgängig machen.
            </strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <virtual if="{patient.plural === 'Klassenarbeiten'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-danger">
          <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
            <strong class="text-danger pl-1 pt-2">Durch das Löschen einer Klassenarbeit werden alle Homeverzeichnisse der Teilnehmer ebenfalls gelöscht.
              Sammeln Sie die Dateien der Klassenarbeits-Teilnehmer ein, bevor Sie die Klassenarbeit löschen!<br/><br/>
              Sie können die Aktion nicht rückgängig machen.
            </strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <virtual if="{patient.plural === 'Profile'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-danger">
          <span><paedml-icon icon="fas-exclamation-triangle" simple="danger" />
            <strong class="text-danger pl-1 pt-2">Sie können die Aktion nicht rückgängig machen.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <virtual if="{patient.plural === 'Schularten'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">Die Schulart kann nur gelöscht werden, wenn zuvor alle Klassen, Lehrer, Schüler und Admin-Konten gelöscht wurden.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <virtual if="{patient.plural === 'Domänen'}">
        <div class="p-2"></div>
        <div class="mt-2 mr-1 p-2 border border-info">
          <span><paedml-icon icon="fas-info-circle" simple="info" />
            <strong class="text-info pl-1 pt-2">In diesem Fall bedeutet es: Diese Aktion hebt die Verknüpfung mit der ausgewählten externen Domäne auf. 
                Es können keine Benutzer neu mit dieser gekoppelt werden.</strong>
          </span>
        </div>
        <div class="p-1"></div>
      </virtual>

      <div class="row" if="{parent.doredirect}">
        <div class="m-2 p-2">
          <span class="pl-1 pt-2">Es werden Jobs erstellt, um {patient.plural} zu löschen.</span>
          <p></p>
          <paedml-form-input name="redirect" placeholder="Nach der Ausführung zur 'JobQueue'-Ansicht springen" fgclass="py-1"
            type="checkbox" revlut="jaNein"
            />
        </div>
      </div>
    </yield>
  </paedml-modal>

  <script>
    import * as R from 'ramda'
    import {toTrueMap} from '../util/mapping-util'
    import {patientFormatter} from '../util/modal-util'
    import Session from '../store/session'

    this.prefix = opts.prefix
    this.patient = this.opts.patient || {
      singular: 'Benutzerkonto',
      plural: 'Benutzerkonten',
      as: 'das'
    }
    this.data = {}
    this.api = this.opts.api
    this.unescape = this.opts.unescape
    this.doredirect = this.opts.doredirect || false

    this.getPatientText = () => {
      const data = this.data
      if (data.ids && data.ids.length === 1 && this.patient.as)  {
        const selected = this.patient.as === 'den' ? 'ausgewählten' : this.patient.as === 'den / die' ? 'ausgewählte(n)' : 'ausgewählte'
        return `${this.patient.as} ${selected} ${this.patient.singular} "${data.idTexts[0]}"`
      }
      return `ausgewählte ${this.patient.plural}`
    }

    this.on('dataRequested', (selectedRows) => {
      this.data = { ids: [], idTexts: [] }
      const idCol = this.opts.idcol || 0
      const idTextCol = this.opts.idtextcol || 1
      selectedRows.each(v => {
        this.data.ids.push(v[idCol])
        this.data.idTexts.push(v[idTextCol])
      })
      this.data.redirect = false
    })

    this.clearValuesFromSession = (ids) => {
      if(!ids.length) return

      switch(this.patient.plural) {
        case 'Räume': 
          Session.map.rooms = R.dissoc(ids[0], Session.map.rooms)
          return
        case 'Schularten':
          Session.map.schularten = R.dissoc(ids[0], Session.map.schularten)
          return
      }
    }

    this.submitHandler = formData => {
      if (formData.ids.length < 1) return Promise.resolve('Löschaktion abgebrochen')

      if (this.unescape === 'true') {
        formData.ids = formData.ids.map(unescape)
      }

      const idLut = R.fromPairs(R.zip(formData.ids, formData.idTexts))
      const deleteMessageSuccess = formatter(idLut, true)
      const deleteMessageFailure = formatter(idLut, false)

      return this.api.remove(formData.ids).then(rsp => {
        const [deletedIds, status] = rsp
        if (status.error) return Promise.reject('Kommunikationsfehler mit dem Server.')
        const {succeeded, failed} = deletedIds
        this.clearValuesFromSession(succeeded)

        if (failed.length) {
          if (succeeded.length) {
            // partly success
            const sLut = toTrueMap(R.map(id => idLut[id], succeeded))
    
            // remove deleted entries
            $('span.tag', this.refs.tip).each(function(i,e) { 
              if (sLut[$(e).text()]) $('span', e).click()
            })

            this.parent.trigger('reloadTable')
            if (this.opts.relogin) window.request.relogin()
            return Promise.reject([deleteMessageSuccess(succeeded), deleteMessageFailure(failed)])
          }
          this.parent.trigger('reloadTable')
          return Promise.reject(deleteMessageFailure(failed))
        }
        
        if (this.doredirect && this.data.redirect) setTimeout(() => { 
          $('body').removeClass('modal-open')
          window.location.href = '/#jobverwaltung/jobs' 
        }, 500)
        
        this.parent.trigger('reloadTable')
        if (this.opts.relogin) window.request.relogin()
        return deleteMessageSuccess(succeeded)
      })
    }

    const deleteAction = this.patient.plural === 'Domänen' ? 'entfernt' : 'gelöscht'
    const formatter = patientFormatter(this.patient, deleteAction)
  </script>
</paedml-modal-delete>
