import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-form-input-pw.tag'

<paedml-modal-info-raum>
  <paedml-modal prefix={opts.prefix} heading={opts.heading} okbutton="OK" opname={opts.opname} edit={opts.edit} selectedrowsfunc={opts.selectedrowsfunc} idcol={opts.idcol} idtextcol={opts.idtextcol}
    submitfunc={submitHandler}>
  
    <yield to="fields">

      <div if={parent.loading} class="d-flex justify-content-center pt-5 pb-5">
        <p class="my-auto">Computer im Raum werden aktualisiert...</p>
        <div class="sk-wave">
          <div class="sk-rect sk-rect1"></div>
          <div class="sk-rect sk-rect2"></div>
          <div class="sk-rect sk-rect3"></div>
          <div class="sk-rect sk-rect4"></div>
          <div class="sk-rect sk-rect5"></div>
        </div>
      </div>

      <table id="{opts.prefix}-modal-table" style="width: 100%;" class="table table-striped table-hover">
      </table>

    </yield>
  </paedml-modal>
    
    <script>
      import {} from '../util/modal-util'
      import * as i18n from '../config/i18n/german'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'
      import moment from 'moment'
      import API from '../api'

      
      this.opname = this.opts.opname
      this.edit = this.opts.edit ? true : false
      this.data = {}
      this.api = this.opts.api
      this.session = Session

      this.table = null

      this.on('update', () => {
        this.loading = false
      })

      this.on('rowMappingRequested', cols => {
        this.loading = true
        API.computer.getInRoom(cols[0]).then(rsp => {
          this.loading = false
          let [computers, status] = rsp
          if (status.error) {
            computers = []
          }

          const selector = `#${opts.prefix}-modal-table`
          this.table = $(selector).DataTable( {
            data: computers.map(e => ({...e, isOnline: e.isOnline ? 'ja' : 'nein'})),
            columns: [
              { data: 'name', title: 'Name' },
              { data: 'ipAddress', title: 'IP' },
              { data: 'username', title: 'Benutzer' },
              { data: 'isOnline', title: 'An' }
            ],
            paging: false,
            info: false,
            searching: false,
            oLanguage: i18n.short,
          } )

          const tableData = this.table.data()

          $('tbody tr', selector).each((i,e) => {
            $(e).tooltip({
              title: this.formatComputerInfo(tableData[i]),
              placement: 'top',
              trigger: 'click focus hover',
              delay: { "show": 1000, "hide": 10 }
            })
          })

          this.update()
        })
      })

      this.on('hide', () => {
        if (this.table) this.table.destroy()
        this.table = null
      })

      this.formatComputerInfo = t => {
        if (!t) return ''
        return `Gesperrt: ${t.isBlocked ? 'ja' : 'nein'}, Internet gesperrt: ${t.isInternetLocked ? 'ja' : 'nein'}`
      }

      this.submitHandler = formData => {
        return Promise.resolve('OK')
      }

    </script>
</paedml-modal-info-raum>
