import './paedml-card.tag'
import './paedml-modal-pw.tag'
import './paedml-modal-delete.tag'
import './paedml-icon.tag'
import './paedml-icon-modal.tag'
import './paedml-modal-createoredit-lehrer.tag'
import './paedml-modal-info-lehrer.tag'
import './paedml-modal-usersync.tag'
import './paedml-modal-fix-perms.tag'
import './paedml-modal-activate-user.tag'
import './paedml-modal-verify-externalid.tag'

<paedml-card-lehrer>
  <paedml-card config={opts.config} perm={opts.perm} siblings={opts.siblings}>
     <virtual if="{selectedNone() && isPermitted('sync')}">
      <paedml-icon-modal icon="fas-recycle" target="#{prefix}-sync-modal" title="Lehrer*innen synchronisieren" />
      <paedml-modal-usersync prefix="{prefix}-sync" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: AD &lt;-&gt; DB Synchronisation" opname="OK" api={restApi}
        variant="lehrer" />
    </virtual>

    <virtual if={canCreate()}>
      <paedml-icon-modal icon="anlegen" target="#{prefix}-create-modal" title="Lehrer*in anlegen" />
      <paedml-modal-createoredit-lehrer prefix="{prefix}-create" usersuffix="{prefix}" 
        heading="{config.contentTitle}: Lehrer*in anlegen" opname="Lehrer*in anlegen" api={restApi} />
    </virtual>

    <virtual if="{canSeeInfo()}">
      <paedml-icon-modal icon="fas-barcode" target="#{prefix}-see-modal" title="Lehrer*in-Info ansehen" />
      <paedml-modal-info-lehrer prefix="{prefix}-see" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Info" opname="OK" api={restApi} />
    </virtual>

    <virtual if="{canChangePassword()}">
      <paedml-icon-modal icon="passwort-bearbeiten" target="#{prefix}-pw-modal" title="Kennwort ändern" />
      <paedml-modal-pw prefix="{prefix}-pw" heading={config.contentTitle} selectedrowsfunc={getSelectedRows}
        variant="lehrer" preset="{config.preset ? config.preset.password : {}}" api={restApi} />
    </virtual>
    
    <virtual if="{selectedOneOrMore() && isPermitted('fix')}">
      <paedml-icon-modal icon="fas-folder-open" target="#{prefix}-fix-modal" title="Homeverzeichnis bearbeiten" />
      <paedml-modal-fix-perms prefix="{prefix}-fix" edit={true} selectedrowsfunc={getSelectedRows}
        variant="fix" edit={true}
        heading="{config.contentTitle}: Homeverzeichnis bearbeiten" opname="Korrekturen ausführen" api={restApi} />
    </virtual>

    <virtual if="{canEdit()}">
      <paedml-icon-modal icon="bearbeiten" target="#{prefix}-edit-modal" title="Lehrer*in bearbeiten" />
      <paedml-modal-createoredit-lehrer prefix="{prefix}-edit" edit={true} selectedrowsfunc={getSelectedRows}
        heading="{config.contentTitle}: Lehrer*in bearbeiten" opname="Änderung speichern" api={restApi} />
    </virtual> 

    <virtual if="{selectedOneOrMore() && isPermitted('activate')}">
      <paedml-icon-modal icon="fas-unlock-keyhole" target="#{prefix}-run-activate-user-modal" title="Lehrer*in(nen) (de)aktivieren" />
      <paedml-modal-activate-user prefix="{prefix}-run-activate-user" selectedrowsfunc={getSelectedRows}
        heading="Lehrer*in(nen) (de)aktivieren" opname="Ausführen" api={restApi} variant='lehrer' />
    </virtual>

    <virtual if="{selectedOneOrMore() && isPermitted('externalId')}">
      <paedml-icon-modal icon="far-id-badge" target="#{prefix}-verify-externalid-modal" title="Externe ID verifizieren" />
      <paedml-modal-verify-externalid prefix="{prefix}-verify-externalid" selectedrowsfunc={getSelectedRows}
        heading="Externe ID verifizieren" opname="Verifizieren" api={restApi} variant='lehrer' />
    </virtual>

    <virtual if="{canDelete()}">
      <paedml-icon-modal icon="loeschen" target="#{prefix}-delete-modal" title="Lehrer*in(nen) löschen" />
      <paedml-modal-delete heading="{config.contentTitle}: Lehrer*in(nen) löschen" prefix="{prefix}-delete" 
        selectedrowsfunc={getSelectedRows} api={restApi} doredirect={true}/>
    </virtual>
  </paedml-card>

</paedml-card-lehrer>
