import './paedml-modal.tag'
import './paedml-form-input.tag'
import './paedml-icon.tag'

<paedml-modal-impressum>
  <paedml-modal prefix={opts.prefix} heading="Impressum" opname="OK" submitfunc={submitHandler} large={true}
    okbutton="OK"
  >
  
    <yield to="fields">

      <div class="paedml-input-group">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <span>Impressum nach § 5 TMG und § 55 RStV</span><br/>
            <span><strong>Landesmedienzentrum Baden-Württemberg</strong></span><br/>
            <span>Standort Karlsruhe</span>
            <p class="pt-2">
              Moltkestraße 64, 76133 Karlsruhe<br/>
              Postfach 21 07 55, 76057 Karlsruhe
            </p>

            <p class="pt-2">
              Telefon: 0711 4909-6399<br/>
              Telefax: 0721 8808-68<br/>
              E-Mail: <a href="mailto:lmz@lmz-bw.de">lmz@lmz-bw.de</a><br/>
            </p>
          </div>
          <div class="col-sm-12 col-md-6">
            <span> </span><br/>
            <span><strong>Landesmedienzentrum Baden-Württemberg</strong></span><br/>
            <span>Standort Stuttgart</span>
            <p class="pt-2">
              Rotenbergstraße 111, 70190 Stuttgart<br/>
              Postfach 131261, 70069 Stuttgart
            </p>

            <p class="pt-2">
              Telefon: 0711 4909-6399<br/>
              Telefax: 0711 2850-780<br/>
              E-Mail: <a href="mailto:lmz@lmz-bw.de">lmz@lmz-bw.de</a><br/>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <p class="py-1">
              Das Landesmedienzentrum Baden-Württemberg (LMZ) ist eine rechtsfähige Anstalt des öffentlichen Rechts.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-lg-4">
            <strong>Vertretungsberechtigte</strong>
            <p>Michael Zieher, Direktor</p>
          </div>
          <div class="col-sm-12 col-lg-4">
            <strong>Zuständige Aufsichtsbehörden</strong>
            <p>Das LMZ untersteht der Rechtsaufsicht des Ministeriums für Kultus, Jugend und Sport Baden-Württemberg.</p>
            <p>UID-Nr. (Umsatzsteueridentifikationsnummer): DE335726149.</p>
          </div>
          <div class="col-sm-12 col-lg-4">
            <strong>Inhaltlich Verantwortlich i.S.d. TMG</strong>
            <p>Michael Zieher, Direktor</p>
          </div>
        </div>
      </div>

    </yield>
  </paedml-modal>
    
    <script>
      import {autoMapRowToData} from '../util/modal-util'
      import store from '../store'
      import { client } from '../util/api-util'
      import * as mapper from '../mapper'
      import * as R from 'ramda'
      import Session from '../store/session'
      import Masterdata from '../config/masterdata'

      this.on('mount', () => {
        this.version = $('meta[name=version]').attr('content')
        this.backendVersion = $('meta[name=bev]').attr('content')
        this.update()
      })
    </script>
</paedml-modal-impressum>
