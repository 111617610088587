import Session from '../store/session';
import {initSession} from '../store/session'
import { client } from '../util/api-util'
import * as mapper from '../mapper'
import * as R from 'ramda'
import {isPermittedForRoles} from '../util/permission'

export const logout = () => {
    client.logout()
    initSession()
}

export const login = () => {
    return client.login()
        .then(toUserState, err => Promise.reject(err))
}

export const loginWithRefreshToken = refreshToken => {
    return client.loginWithRefreshToken(refreshToken).then(toUserState, err => Promise.reject(err))
}

export const loginWithUsernameAndPassword = (username, password) => {
    return client.loginWithUsernameAndPassword(username, password)
        .then(toUserState, err => Promise.reject(err))
}

const toUserState = (userDetail) => {

    Session.profile = userDetail.profile
    
    $.getJSON('/data/help.json', null, rsp => {
        Session.help = rsp.map((k,index) => ({...k, index}))
    })

    if (isPermittedForRoles('rechteverwaltung/system-administratoren', userDetail.roles)) {
        client.get('/api/administrators/systemAdmin').then(rsp => {
            const [sysadms, status] = rsp
            if (status.error) {
                return
            }
           Session.lut.sysadmin = sysadms
        })
    }

    if (isPermittedForRoles('rechteverwaltung/schulart-administratoren', userDetail.roles)) {
        client.get('/api/administrators/schoolTypeAdmin').then(rsp => {
            const [sadms, status] = rsp
            if (status.error) {
                return
            }
           Session.lut.sadms = sadms
        })
    }

    if (isPermittedForRoles('raumverwaltung/raum', userDetail.roles)) {
        client.get('/api/rooms').then(rsp => {
            const [rooms, status] = rsp
            if (status.error) {
                return
            }
            rooms.forEach(v => {
                Session.map.rooms[v.id] = v.name
                Session.lut.rooms[v.name] = v.id
            })
    
            if (userDetail.mainSchoolType) {
                Session.room = userDetail.room = 'NICHT_ZUGEWIESEN'
                client.get('/api/rooms/Current').then(rrsp => {
                    const [room, status] = rrsp
                    if (status.error || !room) {
                        Session.room = userDetail.room = 'NICHT_ZUGEWIESEN'
                    } else {
                        userDetail.room = room.name
                        Session.room = room.name
                    }
                })
            }
        })
    }

   if (isPermittedForRoles('wartung/projekte', userDetail.roles) || isPermittedForRoles('meine/projekte', userDetail.roles)) {
        client.get('/api/projects').then(rsp=>{
            let [projects, status] = rsp
            if (status.error) {
                console.error('cannot retrieve settings', status)
            }
            Session.projects = projects || []
            mapper.mapProjectsWithMembers(projects)
        }) 
    }

    if (isPermittedForRoles('profilverwaltung', userDetail.roles)) {
        client.get('/api/profileManagement/platforms').then(rsp => {
            const [platforms, status] = rsp
            if (status.error) {
                return
            }
            platforms.forEach(v => {
                let name = v.name.replace(' ', ' ')
                Session.map.platforms[v.suffix] = name
                Session.lut.platforms[name] = v.suffix
            })
        });
    }

    client.get('/api/tags').then(rsp => {
        const [tags, status] = rsp
        if (status.error) {
            return
        }
        Session.map.tags = tags
        tags.forEach(t => {
          //  Session.map.tags[t] = t
          //  Session.lut.tags[t] = t
        })
    });

    return client.get('/api/settings').then(rsp => {
            let [settings, status] = rsp
            if (status.error) {
                console.error('cannot retrieve settings', status)
            }
            Session.settings = settings || {}
            return client.get('/api/school/schoolTypes')
        })
        .then(res => {
            const [schulartenWithKlassen, status] = res
            mapper.mapSchulartenWithKlassen(schulartenWithKlassen)
            const userState = {
                ...mapper.mapUserDetail(R.assoc('schularten', Session.map.schularten, userDetail)),
                loggedIn: true
            }
            return userState
        })
}
